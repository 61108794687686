import { Button as ButtonProps, ButtonTranslation, directus, limiter } from '../../../../../../utilities/directus';
import styled from 'styled-components';
import { Paragraph } from '../../../../../typography/Typography';
import { useEffect, useState } from 'react';
import { PartialItem } from '@directus/sdk';
import { useGlobalContext } from '../../../../../contexts/global/GlobalContext';
import { Link } from 'react-router-dom';
import Theme from '../../../../../../style/theme';
import { PageBlockProps } from '../../PageContent';
import BlockContainer from './BlockContainer';
import { useTextContext } from '../../../../../contexts/text/TextContext';

const Button = (props: {blockData: ButtonProps, label?: string} & PageBlockProps) => {
	const [dataState, setDataState] = useState<number>(0); // 0 === not begun gathering data || 1 === started gathering data || 2 === gathered data
	const [buttonData, setButtonData] = useState<PartialItem<ButtonTranslation[]>>([]);

	const {getFileURL} = useGlobalContext();
	const {t} = useTextContext();
	const GreenArrowURL = getFileURL && getFileURL('d6c9df4a-9c0e-47ce-b4f1-cf85ebc17277');
	const WhiteArrowURL = getFileURL && getFileURL('e4ccf404-ac26-40ac-b2b3-af84b291b0c2');

	const getButtonData = async () => {	
		setDataState(1);
		await limiter.removeTokens(1);
		await directus.items('button_blocks_translations').readByQuery({
			filter:{id:	{_eq: props.blockData.id}}})
			.then((a) => {
				setButtonData(a.data?a.data:[]);
				setDataState(2);
			}).catch((e) => console.error(e, 'catching error'));
	};

	useEffect(() => {
		getButtonData();
	},[]);

	useEffect(() => {
		if(dataState === 2) props.onLoaded && props.onLoaded();
	},[dataState]);

	return (
		<BlockContainer style={props.blockData.whitespace_top_bottom ? {} : {padding: 0}}>					
			<Backdrop themeId={props.blockData.theme}/>
			{props.blockData.type === 1 ? <AlignmentBox alignment={props.blockData.alignment}>
				<StyledALink href={'https://tqwi-p22-01.directus.app/assets/'+props.blockData.URL+'?access_token=j1bberj3bber'} target="_blank" rel="noreferrer">
					<Container themeId={props.blockData.theme}>
						<Wrapper themeId={props.blockData.theme}>
							<Paragraph>
								{t('downloadbutton.label')}<br/>
								{props.label ? <b>{props.label}</b> : <b>{buttonData[0]?.label}</b>}
							</Paragraph>
							<img 
								style={{marginLeft: 'auto', marginTop: 'auto', transform: 'rotate(90deg)'}} 
								src={GreenArrowURL}>
							</img>
						</Wrapper>
					</Container>
				</StyledALink>
			</AlignmentBox>
				:
				<AlignmentBox alignment={props.blockData.alignment}>
					<StyledLink to={props.blockData.URL ? props.blockData.URL : '/home'}>
						<Container themeId={props.blockData.theme}>
							<Wrapper themeId={props.blockData.theme}>
								<Paragraph>
									{t('linkbutton.label')}<br/>
									{props.label ? <b>{props.label}</b> : <b>{buttonData[0]?.label}</b>}
								</Paragraph>
								<img 
									style={{marginLeft: 'auto', marginTop: 'auto'}} 
									src={GreenArrowURL}>
								</img>
							</Wrapper>
						</Container>
					</StyledLink>
				</AlignmentBox>}
		</BlockContainer>
	);
};

//styled components
const Backdrop = styled.div<{themeId: number}>`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: 0 -${Theme.responsive.whitespace.xl * 3}px;
    };

	background-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.primaryLight :
				p.themeId === 2 ? Theme.colors.primary : 
					''};

`;

const StyledLink = styled(Link)`
	text-decoration: none;

`;

const StyledALink = styled.a`
	text-decoration: none;
`;

const AlignmentBox = styled.div<{alignment: number}>`
	width: max-content;
	margin-left: ${p => p.alignment == 2 ? 'auto' : '0'};
`;

const Wrapper = styled.div<{themeId: number}>`
	padding-bottom: 10px;
	margin: 10px;

	color: ${p => p.theme.colors.secondary};

	display: flex;
	flex-direction: row;
	&:hover{
		border-bottom: 3px solid ${p => p.theme.colors.secondary};
	}
	border-bottom: 3px solid ${ 'white'  };

	border-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.neutralLight :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};

`;

const Container = styled.div<{themeId: number}>`
	background: white;
	border: 1px solid ${Theme.colors.secondary};
	text-align: left;
	outline:none;
	text-decoration: none;
	cursor: pointer;

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		width: 95%;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		width: 425px;		
    };

	background-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.neutralLight :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};

`;


 
export default Button;
