import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Theme from '../../../../../../../style/theme';
import Button from '../../../../../../basic/buttons/Button';
import { useGlobalContext } from '../../../../../../contexts/global/GlobalContext';
import { Header1, Paragraph } from '../../../../../../typography/Typography';
import { StyledReactMarkdown } from '../StyledMarkdown';
import { StyledParagraph, SummaryDataObject } from './Summary';

const AdvancedSummary = (props: {dataObject: SummaryDataObject, children?: ReactNode}) => {

	const {getFileURL} = useGlobalContext();

	const NavigationLabelContainer = useRef<any>();
	const NavigationLabelsRef = useRef<any>([]);
	const [activeLabel, setActiveLabel] = useState(0);
	const [skippedFirst, setSkippedFirst] = useState(false);

	const onArrowPress = (up: boolean) => {
		if(up){
			setActiveLabel(a => a < (Object.values(props.dataObject.content).length - 1) ? a + 1 : a);
		}
		else{
			setActiveLabel(a => a > 0 ? a - 1 : a);
		}
	};

	useEffect(() => {
		if(NavigationLabelsRef.current[activeLabel] && skippedFirst)
			NavigationLabelsRef.current[activeLabel].scrollIntoView({behavior: 'smooth', inline: 'start', block: 'nearest'});
		else if (!skippedFirst){
			setSkippedFirst(true);	
		}
	},[activeLabel]);

	return (
		<Container themeId={props.dataObject.data.theme}>
			<Column1>
				<StyledHeader1 themeId={props.dataObject.data.theme}>{props.dataObject.translation.title}</StyledHeader1>
				<Paragraph style={{fontWeight: 'bold'}}>{props.dataObject.translation.description}</Paragraph>
			</Column1>
			<Column2>
				<StyledReactMarkdown 
					themeId={props.dataObject.data.theme ? props.dataObject.data.theme : 0}
					components={
						{p : ({...props}) => <StyledParagraph>{props.children}</StyledParagraph>}
					}
				>
					{props.dataObject.content[Object.keys(props.dataObject.content)[activeLabel]].translation.description || ''}
				</StyledReactMarkdown>
			</Column2>
			<Navigation>
				<Arrows>
					{ getFileURL && 
						<>
							<Arrow 
								disabled={activeLabel === 0}
								onClick={() => {onArrowPress(false);}}
								url={getFileURL('74022c43-11a9-4846-807f-c6faeba34736')}
							/>
							<Arrow 
								disabled={activeLabel === (Object.values(props.dataObject.content).length - 1)}
								onClick={() => {onArrowPress(true);}}
								style={{transform: 'rotate(180deg)', marginLeft: '10px'}} 
								url={getFileURL('74022c43-11a9-4846-807f-c6faeba34736')}
							/>
						</>
					}
				</Arrows>
				<NavigationLabelsContainer>
					<NavigationLabels ref={NavigationLabelContainer}>
						{ Object.values(props.dataObject.content).map((value, index) => 
							<NavigationLabel 
								key={`as-navigation-${index}`} 
								ref={(element) => NavigationLabelsRef.current[index] = element}
								selected={activeLabel === index}
								onClick={() => setActiveLabel(index)}
							>
								{index + 1}.<br/>
								{value.translation.title}
							</NavigationLabel>
						)}
						<Filler/>
					</NavigationLabels>
					<OverflowFade themeId={props.dataObject.data.theme}/>
				</NavigationLabelsContainer>
			</Navigation>
		</Container>
	);
};

// styled components

const Container = styled.div<{themeId: number | undefined}>` // Grid
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content min-content;
    grid-auto-flow: dense;

	@media (max-width: ${Theme.responsive.media.lg}){
		grid-column-gap: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-column-gap: ${Theme.responsive.whitespace.lg / 2}px;
    };
	color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralDark : 
			p.themeId === 1 ? Theme.colors.neutralDark :
				p.themeId === 2 ? Theme.colors.neutralLight : 
					''};
    /* outline: solid 2px red; */
`;

const Column = styled.div`
    margin-bottom: ${Theme.responsive.whitespace.lg / 3}px;
    height: 60vh;
    overflow-y: auto;
`;

const Column1 = styled(Column)`
    @media (max-width: ${Theme.responsive.media.lg}){
        grid-column: span 3 ;
        height: fit-content;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        grid-column: span 1 ;

    };

`;

const Column2 = styled(Column)`

    @media (max-width: ${Theme.responsive.media.lg}){
        grid-column: span 3 ;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        grid-column: span 2 ;

    };
`;

const Navigation = styled.div`
    grid-column: span 3;
    position: relative;
    /* background: blue; */
`;

const Arrows = styled.div`
    border-bottom: solid 4px ${Theme.colors.secondaryLight};
`;

const Arrow = styled(Button)<{url: string | undefined}>`
    height: 15px;
    width: 15px;
    margin: 10px 10px;

	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

    &:disabled{
        filter: grayscale(100%);
    }
`;

const NavigationLabelsContainer = styled.div`
    width: 100%;
    position: relative 
    ;
`;

const NavigationLabels = styled.div`
    display: flex;

    overflow-x: hidden;
    gap: 50px;

    position: relative;

`;

const NavigationLabel = styled.div<{selected:boolean}>`
    max-width: 200px;
    /* word-wrap: break-word; */
    hyphens: auto;

    ${p => p.selected &&  css`
        /* text-decoration: underline; */
        text-shadow: 0px 0px 1px black;
    `}

    margin: 10px 0px;
	cursor: pointer;

`;

const OverflowFade = styled.div<{themeId: number | undefined}>`
    position: absolute;
    top:0;right:0;bottom:0;
    width: 200px;

	/* background: ${p => 
		p.themeId === 0 ? `linear-gradient(90deg, #0080FF00 0%, ${Theme.colors.neutralLight} 100%);` : 
			p.themeId === 1 ? `linear-gradient(90deg, #0080FF00 0%,${Theme.colors.primaryLight} 100%);` :
				p.themeId === 2 ? `linear-gradient(90deg, #0080FF00 0%, ${Theme.colors.primary} 100%);` : 
					''}; */
	pointer-events: none;
`;

const Filler = styled.div`
    flex-basis: 10000px;
    flex-shrink: 0;
    flex-grow: 0;
`;

// Typo

const StyledHeader1 = styled(Header1)<{themeId: number | undefined}>`
	color: ${p => 
		p.themeId === 0 ? Theme.colors.primary : 
			p.themeId === 1 ? Theme.colors.primary :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};
    
    @media (max-width: ${Theme.responsive.media.lg}){
        margin-bottom: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        margin-bottom: ${Theme.responsive.whitespace.lg / 1.75}px;
    };
`;

export default AdvancedSummary;