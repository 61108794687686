import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../../style/theme';
import { BasicLink } from '../../../../basic/buttons/BasicLink';
import Button from '../../../../basic/buttons/Button';
import { useGlobalContext } from '../../../../contexts/global/GlobalContext';
import { CategoryDataEntry } from '../../../../contexts/global/types';
import { Header2, Paragraph } from '../../../../typography/Typography';

const PageContent = (props: {category: CategoryDataEntry}) => {

	const {categories, pages, getFileURL} = useGlobalContext();

	const [focusedPage, setFocusedPage] = useState(0);
	const [skippedFirstRender, setSkippedFirstRender] = useState(false);
	const [cardsPerPage, setCardsPerPage] = useState<number | undefined>(2);
	const [totalPages, setTotalPages] = useState<number | undefined>();
	const [mobile, setMobile] = useState(window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).matches);
 
	const cards = useRef<any>([]);

	useEffect(() => {

		calculateCardsPerPage();

		window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).addEventListener('change', (e) => setMobile(e.matches));
		return (() => {
			window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).removeEventListener('change', (e) => setMobile(e.matches));
		});

	},[]);

	useEffect(() => {
		if(skippedFirstRender)
			scrollTo(focusedPage * (cardsPerPage ? cardsPerPage : 1));
		else{
			setSkippedFirstRender(true);   
		}
	},[focusedPage]);

	const scrollTo = (cardId: number) => {
		cards.current[cardId].scrollIntoView({behavior: 'smooth', inline: 'start', block: 'nearest'});
	};

	const navigate = (increase: boolean) => {

		if(totalPages === undefined) return;

		if(increase && focusedPage != totalPages - 1){
			setFocusedPage(a => a + 1);
		}
		else if(!increase && focusedPage != 0){
			setFocusedPage(a => a - 1);
		}
	};

	const calculatePages = () => {
		if(cardsPerPage === undefined) return;

		setTotalPages(Math.ceil(cards.current.length / cardsPerPage));

	};

	const calculateCardsPerPage = () => {
		if(mobile) {
			setCardsPerPage(1);
		}
		else{
			setCardsPerPage(2);
		}
	};

	useEffect(() => {
		calculateCardsPerPage();
	},[mobile]);

	useEffect(() => {
		if(cardsPerPage)
			calculatePages();
	}, [cardsPerPage]);

	return (
		<Container>
			<Cards>
				{ pages && categories && categories[props.category.data.id ? props.category.data.id : 0].pages.map((pageId, index) => 
					<Card key={`pagecards-${index}-${pageId}`} to={`/page/${pageId}`} ref={(el) => cards.current[index] = el} cardsperpage={cardsPerPage ? cardsPerPage : 1}>
						<Image url={getFileURL && getFileURL(pages[pageId].data.image || '')}>
							<StyledHeader2>{pages[pageId].translation?.title}</StyledHeader2>
						</Image>
						<StyledParagraph>{pages[pageId].translation?.description}</StyledParagraph>
						<CardFooter url={getFileURL && getFileURL( '74022c43-11a9-4846-807f-c6faeba34736' ||'')}/>
					</Card>
				)}
				{ [...Array(cardsPerPage)].map ((value, index) => 
					<FillerCard key={`fillerpagecards-${index}`} cardsperpage={cardsPerPage ? cardsPerPage : 1}/>
				)}
			</Cards>
			<Navigation>
				<NavigationButton disabled={focusedPage === 0} onClick={() => navigate(false)} left={true} url={getFileURL && getFileURL( '74022c43-11a9-4846-807f-c6faeba34736' ||'')}/>
				<NavigationButton disabled={totalPages === undefined || focusedPage === totalPages - 1} onClick={() => navigate(true)} url={getFileURL && getFileURL( '74022c43-11a9-4846-807f-c6faeba34736' ||'')}/>
			</Navigation>
		</Container>
	);
};

// styled components

const Container = styled.div`
	
	@media (max-width: ${Theme.responsive.media.lg}){
		grid-column: span 3;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-column: span 2;
    };
`;


const Cards = styled.div`
	overflow: hidden;
	position:relative;
	width: 100%;
	/* height: 100%; */

	display: flex;
	gap: 10px;
	
	/* @media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg /2}px;
    }; */
`;

const Card = styled(BasicLink)<{cardsperpage: number}>`
	box-sizing: border-box;
	display: flex ;
	flex-direction: column;

	text-decoration: none!important;

	color: black;

	@media (max-width: ${Theme.responsive.media.lg}){
    };
	@media (min-width: ${Theme.responsive.media.lg}){
    };
	width: ${p => `calc(100% / ${p.cardsperpage} - 5px)`};
	
	${p => p.cardsperpage === 1 && `width: ${`calc(100% / ${p.cardsperpage})`};`}

	flex-shrink: 0;

	border: solid ${Theme.colors.secondary} 1.5px;
`;

const FillerCard = styled.div<{cardsperpage: number}>`
    width: ${p => `calc(100% / ${p.cardsperpage})`};
	flex-shrink: 0;
`;

const CardFooter = styled.div<{url: string | undefined}>`
	border-bottom: solid 2px ${Theme.colors.secondary};

	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: 15px 15px;
	background-position: left center;

	height : 20px;


	transform: scaleX(-1); 

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.sm}px;
	margin-top: auto;

    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin:${Theme.responsive.whitespace.lg/4}px ${Theme.responsive.whitespace.lg/2}px  ;
	margin-top: auto;

    };
`;


const Image = styled.div<{url: string | undefined}>`

	position: relative;
	/* width: 100%; */
	aspect-ratio: 1/0.5;
	min-height: 100px;
	margin: 5px;

	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	display: flex;
	align-items: flex-end;

	& :after {
		content: '';
		position: absolute;
		left:0;top:0;right:0;bottom:0;
		background-color: rgba(0,0,0,0.5);
		z-index: -1;
	}
`;


// Styled Typography

const StyledHeader2 = styled(Header2)`

	color: white;
	font-size: 1.2rem;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.sm / 3}px ${Theme.responsive.whitespace.sm - 10}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.lg / 4}px ${(Theme.responsive.whitespace.lg - 10) / 2}px;
    };

	z-index: 2;

`;

const StyledParagraph = styled(Paragraph)`
	font-size: 0.8rem;
	
	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		padding:${Theme.responsive.whitespace.lg / 4}px ${Theme.responsive.whitespace.lg / 2}px;
    };
`;

const Navigation = styled.div`
	display: flex;
	height: 30px;
	/* background: green; */
	margin-top: 10px;
`;

const NavigationButton = styled(Button)<{url: string | undefined, left?: boolean}>`
	border: 2px solid ${Theme.colors.secondary};
	background-color: white;
	height: 30px;
	width: 30px;

	margin-left: ${p => !p.left && 'auto'};
	transform: ${p => !p.left && 'scaleX(-1)'};

	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: 50%;
	background-position: center;

    &:disabled {
        filter: grayscale(100);
    }
`;

export default PageContent;