import { ID, PartialItem } from '@directus/sdk';
import { ReactNode, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Theme from '../../../../../../../style/theme';
import { ContentTranslation, directus, limiter, Summary as SummaryProps, SummaryData, SummaryDataTranslation, SummaryTranslation } from '../../../../../../../utilities/directus';
import { useGlobalContext } from '../../../../../../contexts/global/GlobalContext';
import { useTextContext } from '../../../../../../contexts/text/TextContext';
import { Header1, Header2, Header3, Paragraph } from '../../../../../../typography/Typography';
import { PageBlockProps } from '../../../PageContent';
import BlockContainer from '../BlockContainer';
import { StyledReactMarkdown } from '../StyledMarkdown';
import AdvancedSummary from './AdvancedSummary';

const Summary = (props: {children: ReactNode, blockData: SummaryProps} & PageBlockProps) => {

	const [dataState, setDataState] = useState<number>(0); // 0 === not begun gathering data || 1 === started gathering data || 2 === gathered data
	const [dataObject, setDataObject] = useState<SummaryDataObject | null | undefined>();

	const {language} = useTextContext();
	const {getFileURL} = useGlobalContext();

	useEffect(() => {
		if(dataState === 0){
			getData();
		}
	},[]);

	useEffect(() => {
		if(dataState === 2) props.onLoaded && props.onLoaded();
	},[dataState]);
	
	const getData = async () => {
		setDataState(1);
		const summaryData : SummaryDataObject = {data: props.blockData, translation: {}, content: {}};
		await limiter.removeTokens(1);
		await directus.items('summary_blocks_translations').readByQuery({filter:{summary_blocks_id:{_eq: props.blockData.id}, languages_code: {_eq: language}}}).then((a) => {
			if(a.data) summaryData.translation = a.data[0];
		});

		const relationIds: ID[] = [];
		await limiter.removeTokens(1);
		await directus.items('summary_blocks_summary_blocks_data').readByQuery({filter:{summary_blocks_id:{_eq: props.blockData.id}}}).then((a) => {
			if(a.data) a.data.map((relation) => {
				if(relation.summary_blocks_data_id) relationIds.push(relation.summary_blocks_data_id);
			});
		}).catch((e) => console.error(e, 'catching error'));
		await limiter.removeTokens(1);
		if(relationIds.length > 0) await directus.items('summary_blocks_data').readByQuery({filter:{id:{_in: relationIds}}}).then((a) => {
			if(a.data) a.data.map((data) => {
				if(data.id && summaryData.content) summaryData.content[data.id] = {data: data, translation: {}};
			});
		}).catch((e) => console.error(e, 'catching error'));
		await limiter.removeTokens(1);
		if(relationIds.length > 0) await directus.items('summary_blocks_data_translations').readByQuery({filter:{summary_blocks_data_id:{_in: relationIds}, languages_code: {_eq: language}}}).then((a) => {
			if(a.data) a.data.map((data) => {
				if(data.id && summaryData.content && data.summary_blocks_data_id) summaryData.content[data.summary_blocks_data_id].translation = data;
			});
		}).catch((e) => console.error(e, 'catching error'));

		setDataObject(summaryData);
		setDataState(2);
	};

	return (  
		<BlockContainer>
			{ dataObject && !dataObject.data.advanced ?
				<> {/* Basic */}
					<StyledHeader1 themeId={props.blockData.theme}>{dataObject.translation.title}</StyledHeader1>
					<DataContainer>
						{ Object.values(dataObject.content).map((value, index) => 
							<Data key={`summary-data-${value.data.id}-${index}`}>
								<HeaderColumn><StyledHeader2 themeId={props.blockData.theme}>{value.translation.title}</StyledHeader2></HeaderColumn>
								<ContentColumn>
									<StyledReactMarkdown
										themeId={props.blockData.theme}
										components={
											{p : ({...props}) => <StyledParagraph>{props.children}</StyledParagraph>}
										}
									>
										{value.translation.description ? value.translation.description : ''}
									</StyledReactMarkdown>
								</ContentColumn>
							</Data>
						)}
					</DataContainer>
					<Backdrop themeId={props.blockData.theme}/>
				</>
				: dataObject && dataObject.data.advanced ? 
					<>
						<AdvancedSummary dataObject={dataObject}/>
						<Backdrop themeId={props.blockData.theme}/>
					</>
					: 'Loading'
			}
		</BlockContainer>
	);
};


// styled components

export const Backdrop = styled.div<{themeId: number}>`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: 0 -${Theme.responsive.whitespace.xl * 3}px;
    };

	background-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.primaryLight :
				p.themeId === 2 ? Theme.colors.primary : 
					''};

`;

const StyledHeader1 = styled(Header1)<{themeId: number | undefined}>`
	color: ${p => 
		p.themeId === 0 ? Theme.colors.primary : 
			p.themeId === 1 ? Theme.colors.primary :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};

	@media (max-width: ${Theme.responsive.media.lg}){
        margin-bottom: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-bottom: ${Theme.responsive.whitespace.lg}px;

    };
`;

const StyledHeader2 = styled(Header3)<{themeId: number}>`

	font-weight: bold;

	color: ${p => 
		p.themeId === 0 ? Theme.colors.primary : 
			p.themeId === 1 ? Theme.colors.primary :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};
`;

export const StyledParagraph = styled(Paragraph)<{themeId?: number}>`
	color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralDark : 
			p.themeId === 1 ? Theme.colors.neutralDark :
				p.themeId === 2 ? Theme.colors.neutralLight : 
					''};
`;

const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@media (max-width: ${Theme.responsive.media.lg}){
        gap: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg}px;
    };
`;

const Data = styled.div`
	display: grid;
	grid-template-columns: 30% 70%;

	@media (max-width: ${Theme.responsive.media.lg}){
        gap: ${Theme.responsive.whitespace.sm / 2}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg / 2}px;
    };
	
`;

const HeaderColumn = styled.div`

	@media (max-width: ${Theme.responsive.media.lg}){
		grid-column: span 2;

    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-column: span 1;
    };
`;

const ContentColumn = styled.div`

	@media (max-width: ${Theme.responsive.media.lg}){
		grid-column: span 2;

    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-column: span 1;
    };
`;

// types

export type SummaryDataObject = {
	translation: PartialItem<SummaryTranslation>,
	data: PartialItem<SummaryProps>,
	content: {[key: string] : {data: PartialItem<SummaryData>, translation: PartialItem<SummaryDataTranslation>}}
}
 
export default Summary;