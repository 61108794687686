import styled from 'styled-components';
import Theme from '../../../../style/theme';


// creates space roughly equal to the nav bar
const NavigateBar = styled.div`
	background-color: ${Theme.colors.primary};
	@media (max-width: ${Theme.responsive.media.lg}){
		height: ${`calc(50px + ${(Theme.responsive.whitespace.sm / 3) * 2}px)`};
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		height: ${50 + Theme.responsive.whitespace.lg / 3 + Theme.responsive.whitespace.lg / 3 +'px'};
    };
`;

export default NavigateBar;