import styled from 'styled-components';
import Theme from '../../style/theme';

const Paragraph = styled.p`
    padding: 0;
    margin: 0;
    font-weight: normal;
    line-height: 1.5em;

    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        font-size: 1rem; //determine later
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        font-size: 1.1rem; 
    };
`;

const Header1 = styled.h1`
    padding: 0;
    margin: 0;
    font-weight: bold;

    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        font-size: 1.5rem;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        font-size: 2rem;
    };
`;

const Header2 = styled.h2`
    padding: 0;
    margin: 0;
    font-weight: bold;
    
    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        font-size: 1.1rem;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        font-size: 1.7rem; 
    };
`;

const Header3 = styled.h3`
    padding: 0;
    margin: 0;
    font-weight: normal;
    
    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        font-size: 1rem;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        font-size: 1.2rem;
    };
`;


export {Paragraph, Header1, Header2, Header3};