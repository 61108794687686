import Theme from '../../../../style/theme';
import { useTextContext } from '../../../contexts/text/TextContext';
import { Header1 } from '../../../typography/Typography';
import NavigateBar from '../components/NavigateBar';
import PageContainer from '../components/PageContainer';
import { StyledReactMarkdown } from '../page/blocks/components/StyledMarkdown';

const Contact = () => {

	const {t} = useTextContext();

	return (
		<>
			<NavigateBar/>
			<PageContainer>
				<Header1 style={{color: Theme.colors.primary}}>{t('contact.title')}</Header1><br/>
				<StyledReactMarkdown>{t('contact.data', true)}</StyledReactMarkdown>
			</PageContainer>
		</>
	);
};

export default Contact;