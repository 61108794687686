import { PartialItem } from '@directus/sdk';
import styled from 'styled-components';
import { css } from 'styled-components';
import Theme from '../../../../style/theme';
import { FAQTranslation } from '../../../../utilities/directus';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { Header3, Paragraph } from '../../../typography/Typography';

const Question = (props: {setSelected: (val: number | undefined) => void, selected?: number, faq?: PartialItem<FAQTranslation>, index: number}) => {

	const {getFileURL} = useGlobalContext();
	const arrow = getFileURL && getFileURL('3b577bf7-01c6-484d-9c2e-d303caa1ea09');

	const openQuestion = () => {
		if(props.selected === props.index){
			props.setSelected(undefined);
		} else {
			props.setSelected(props.index);
		}
	};

	return(
		<>
			<Wrapper>
				<QuestionBox onClick={openQuestion}>
					<Header3 style={{fontWeight: 'bold', color: Theme.colors.primary}}>{props.faq?.title}</Header3>
					<Arrow img={arrow ? arrow : ''} selected={props.selected === props.index ? true : false}><div></div></Arrow>
				</QuestionBox>
				{props.selected === props.index && 
				<Paragraph style={{paddingTop: '10px', paddingBottom: Theme.responsive.whitespace.sm}}>
					{props.faq?.text}
				</Paragraph>}			
			</Wrapper>			
		</>
	);
};

// styled components

const Wrapper = styled.div`
	width: 75%;
	text-align: left;
`;

const QuestionBox = styled.div`
	display: flex;
    flex-direction: row;
	align-items: center;

    border-bottom: 2px solid ${Theme.colors.primary};
    
    padding: ${Theme.responsive.whitespace.lg+'px'} 0px 10px 0px;
`;

const Arrow =  styled.div<{selected: boolean, img: string}>`

	position : relative;
	margin-left: auto;
	
	${(p: { selected: any; }) => p.selected && css`
			transform: rotate(180deg);
	`}

	background-image: url(${p  => p.img});
	background-size: 100% 100%;
    background-repeat: no-repeat;

	height: 10px;
	width: 20px;

	transition: transform 0.3s;
	transform-origin: center;
`;

export default Question;