import { createContext, useContext, useEffect, useState } from 'react';
import { directus, limiter } from '../../../utilities/directus';
import { ContextProps, Language, TextDataRecord } from './types';

const TextContext = createContext({t: (key:string, markdown?: boolean): string => {return('data not loaded');}, language: 'nl-NL'});
const language : Language = 'nl-NL'; // nl-NL 

const TextContextProvider = (props: ContextProps) => {
	
	const [data, setData] = useState<undefined | TextDataRecord>();

	useEffect(() => {
		initialize();
	},[]);

	const initialize = async() => {

		const textsObject: TextDataRecord = {};
		await limiter.removeTokens(1);
		await directus.items('text_translations').readByQuery({filter:{languages_code: {_eq: language}}}).then((a) => {
			a.data && a.data.map((text) => 
				textsObject[`${text.text_key}`] = {text: `${text.text}`, markdown: `${text.markdown}`}
			);
		}).catch((e) => console.error(e, 'catching error'));

		setData(textsObject);

	};

	const t = (key: string, markdown?: boolean): string => {
		if(data){
			if(key in data){
				if(!markdown)
					return(data[key].text);
				else
					return(data[key].markdown);
			}
			else{
				console.error(`key '${key}' is not present in text data`);
				return(`invalid key: ${key}`);
			}
		}
		else{
			console.error('data requested from textContext while data is undefined');
			return('koekjes');
		}
	};

	return (
		<>	
			<TextContext.Provider value={{t, language}}>
				{ data &&
					<>{props.children}</>
				}
			</TextContext.Provider>
		</>

	);

};

const useTextContext = () => useContext(TextContext);


export {TextContext, TextContextProvider, useTextContext};
