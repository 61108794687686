import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../../style/theme';
import { BasicLink } from '../../../../basic/buttons/BasicLink';
import Button from '../../../../basic/buttons/Button';
import { useGlobalContext } from '../../../../contexts/global/GlobalContext';
import SlidingPanel from './components/SlidingPanel';

const MobileNavigation = () => {

	//#region Context Imports

	const {getFileURL} = useGlobalContext();

	//#endregion
	//#region Scrolling

	useEffect(() => {

		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		swc.addEventListener('scroll', scrollListener, { passive: true });

		return () => {
			swc.removeEventListener('scroll',scrollListener);
		};

	},[]);

	const [scrollPositionAtTop, setScrollPositionAtTop] = useState<boolean>(true);
	const scrollListener = (e: any) => {
		const position = e.srcElement.scrollTop;
		setScrollPositionAtTop(position === 0 ? true : false);
	};

	//#endregion
	//#region SlidingPanel

	const [slidingPanelOpen, setSlidingPanelOpen] = useState(false);
	const [activePanel, setActivePanel] = useState<string | undefined>('navigate');

	const setSlidingPanel = (state: boolean, panel?: string) => {
		if(panel){
			setActivePanel(panel);
		}
		setSlidingPanelOpen(state);
	};

	//#endregion
	return (
		<>
			<Container seethrough={scrollPositionAtTop}>
				{/* hamburger button */}
				{/* logo centered */}
				<Icon
					url={getFileURL && getFileURL('29c28d98-0b08-41c7-b947-2efc9bbb1f19')} 
					onClick={() => setSlidingPanel(true, 'navigate')}
				/>
				<Logo to={'/home'} url={getFileURL && getFileURL('0303e32c-5f41-443d-b33d-1706cc781700')}/>
				<Icon
					url={getFileURL && getFileURL('c53bf9fb-997b-4ecb-9311-80cbe35fcd1c')} 
					onClick={() => setSlidingPanel(true, 'search')}
				/>
			</Container>
			<SlidingPanel activePanel={activePanel} close={() => setSlidingPanel(false)} open={slidingPanelOpen}/>
		</>

	);
};

// styled components

const Container = styled.div<{seethrough: boolean}>`
	
	display: flex;

	background: ${p => p.seethrough ? 'none' : Theme.colors.primary};
	height: 50px;
	color: ${Theme.colors.neutralLight};


	transition: background-color .25s;

	padding: ${Theme.responsive.whitespace.sm / 3}px;
	gap: ${Theme.responsive.whitespace.sm / 3}px; 
`;

const Logo = styled(BasicLink)<{url: string | undefined}>`
	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	/* width: 100px; */

	justify-self: center;

	flex-grow: 1;
`;

const Icon = styled(Button)<{url?: string | undefined}>`
	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: 80%;
	background-position: center;

	height: 50px;
	width: 50px;

	/* padding: 10px; */
`;

export default MobileNavigation;