import styled from 'styled-components';
import Theme from '../../../../../style/theme';
import { useGlobalContext } from '../../../../contexts/global/GlobalContext';
import { Header2, Paragraph } from '../../../../typography/Typography';
import BlockContainer from '../../page/blocks/components/BlockContainer';
import PageContent from './PageContent';

const HomeContentInformation = () => {

	const {categories, pages, getFileURL} = useGlobalContext();

	return (
		<Container>
			{categories && Object.values(categories).map((category, index) =>
				<Category key={index}>
					<CategoryContent>
						<Header2 style={{color: Theme.colors.primary}}>{category.translation?.name}</Header2><br/>
						<Paragraph>{category.translation?.description}</Paragraph>
					</CategoryContent>
					<PageContent category={category}/>
				</Category>
			)}
		</Container>
	);
};

const Container = styled(BlockContainer)`
	display: flex;
	flex-direction: column;

	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg}px;
    };
`;

const Category = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr) ;
	

	@media (max-width: ${Theme.responsive.media.lg}){
		grid-gap: ${Theme.responsive.whitespace.sm / 2}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-gap: ${Theme.responsive.whitespace.lg}px;
    };

`;

const CategoryContent = styled.div`

	@media (max-width: ${Theme.responsive.media.lg}){
		grid-column: span 3;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		grid-column: span 1;
    };
`;

export default HomeContentInformation;