import { PartialItem } from '@directus/sdk';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Theme from '../../../../../../style/theme';
import { directus, limiter, QuestionTranslation } from '../../../../../../utilities/directus';
import BasicButton from '../../../../../basic/buttons/Button';
import { Header2, Paragraph } from '../../../../../typography/Typography';
import { Question as QuestionProps } from '../../../../../../utilities/directus';
import BlockContainer from './BlockContainer';
import { PageBlockProps } from '../../PageContent';
 
const Question = (props: {blockData: QuestionProps} & PageBlockProps) => {
	const [dataState, setDataState] = useState<number>(0); // 0 === not begun gathering data || 1 === started gathering data || 2 === gathered data
	const [questionData, setQuestionData] = useState<PartialItem<QuestionTranslation[]>>([]);
	const [selected, setSelected] = useState<number>();

	const getQuestionData = async () => {	
		await limiter.removeTokens(1);
		await directus.items('question_blocks_translations').readByQuery({
			filter:{question_blocks_id:	{_eq: props.blockData.id}}})
			.then((a) => {
				setQuestionData(a.data?a.data:[]);
				setDataState(2);
			}).catch((e) => console.error(e, 'catching error'));
	};

	useEffect(() => {
		getQuestionData();
	},[]);

	useEffect(() => {
		if(dataState === 2) props.onLoaded && props.onLoaded();
	},[dataState]);

	const pageLink = questionData[0]?.link_to_page;

	return (  
		<BlockContainer>
			<Backdrop/>
			<Container>					
				<Header2>{questionData[0]?.question}</Header2>
				<ButtonRow answered={false}>
					<div style={{width: '100%'}}>
						<StyledButton usable={selected === 1 ? true : false} onClick={() => setSelected(1)}><Paragraph style={{fontWeight: 'bold'}}>{questionData[0]?.option_1_label}</Paragraph></StyledButton>
					</div>
					<div style={{width: '100%'}}>
						<StyledButton usable={selected === 2  ? true : false} onClick={() => setSelected(2)}><Paragraph style={{fontWeight: 'bold'}}>{questionData[0]?.option_2_label}</Paragraph></StyledButton>
					</div>
				</ButtonRow>				
				<AnswerWrapper style={{visibility: `${selected ? 'visible' : 'hidden'}`}}> 
					<Paragraph style={{paddingBottom: '25px'}}>
						{selected === 1 ? questionData[0]?.option_1_result : questionData[0]?.option_2_result}
					</Paragraph>					
				</AnswerWrapper>	
			</Container>
		</BlockContainer>
	);
};

//styled components
const Container = styled.div`
	color: white;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

const Backdrop = styled.div`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: 0 -${Theme.responsive.whitespace.xl * 3}px;
    };

	background-color: ${Theme.colors.secondary};
`;

const AnswerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		margin-top: ${Theme.responsive.whitespace.sm +'px'};
		padding: 0px ${Theme.responsive.whitespace.sm +'px'} ;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-top: ${Theme.responsive.whitespace.lg +'px'};
		padding: 0px ${Theme.responsive.whitespace.lg*3 +'px'};
    };
`;

const ButtonRow = styled.div<{answered: boolean}>`
	display: flex;
	width: 100%;
	align-items: stretch;
	justify-content: stretch;
	align-content: stretch;

	pointer-events: ${p => p.answered ? 'none' : ''};
	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm +'px'};
		margin-top: ${Theme.responsive.whitespace.sm +'px'};
		flex-direction: column;
	}
    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg +'px'};
		margin-top: ${Theme.responsive.whitespace.lg +'px'};
		flex-direction: row;
    };
`;

const StyledButton = styled(BasicButton)<{usable: boolean}>`
	border: 2px solid white;
	background: none;
	width: 100%;
	height: 100%;
	color: white;
    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm/2+'px'} ${Theme.responsive.whitespace.lg/1.5+'px'};
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        padding: ${Theme.responsive.whitespace.sm/3+'px'} ${Theme.responsive.whitespace.lg*1.5+'px'};
    };

	${p => p.usable && css`
		background-color: ${Theme.colors.primaryLight};
		color: ${Theme.colors.primary};
	`}

	&:focus{
		outline: none;
	}

`;
 
 
export default Question;
