import { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Theme from '../../../../../../style/theme';
import Button from '../../../../../basic/buttons/Button';
import { useGlobalContext } from '../../../../../contexts/global/GlobalContext';
import NavigatePanel from './NavigatePanel';
import SearchPanel from './SearchPanel';

const SlidingPanel = (props: SlidingPanelProps) => {

	//#region Context Imports

	const {getFileURL} = useGlobalContext();

	//#endregion

	const ActivePanel = props.activePanel && panels[`${props.activePanel}`];
	const panel = useRef<HTMLDivElement | null>(null);

	const onOpen = () => {
		if(panel.current){
			panel.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		if(props.open)
			onOpen();
	},[props.open]);

	return(
		<>
			<Container open={props.open}>
				<Panel open={props.open} ref={panel}>
					{ ActivePanel &&
						<ActivePanel close={() => props.close()} open={props.open}/>
					}
					<Close 
						url={getFileURL && getFileURL('dfd07319-bc32-41ef-aaab-bfe5e69d182f')} 
						open={props.open}
						onClick={(e) => {e.currentTarget.blur();props.close();}}
					/>
				</Panel>
				<CloseStrip
					url={getFileURL && getFileURL('dfd07319-bc32-41ef-aaab-bfe5e69d182f')} 
					open={props.open}
					onClick={(e) => {e.currentTarget.blur();props.close();}}
				/>

			</Container>
		</>
	);
};

//#region styled components

const Container = styled.div<{open: boolean}>`
	display: flex;

	position: absolute;
	left:0;top:0;
	height: 100vh;
	width: 100%;

	background-color: ${p => p.open? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' };
	pointer-events:  ${p => p.open? 'all' : 'none' };

	transition: background-color 0.5s;

	color: ${Theme.colors.neutralLight};
`;

const Panel = styled.div<{open: boolean}>`

	position: relative;

	box-sizing: border-box;

	width: 80%;
	height: 100%;

	background-color: ${Theme.colors.primary};

	${p => p.open ? css`

		transform: translateX(0);

		` : css`

		transform: translateX(-100%);
	`}

	transition: all .5s ease-out;

	padding: ${Theme.responsive.whitespace.sm}px;
	padding-bottom: 0;

	overflow-y: auto;
`;

const CloseStrip = styled.div<{url: string | undefined, open: boolean}>`


	height: 100%;

	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: 0;
	background-position: center 5%;

	width: 20%;
	
	transition: all 0.3s;
	visibility: ${p => p.open ? 'visible' : 'hidden'};
	opacity: ${p => p.open ? '1' : '0'};
	/* transition-delay: 0.5s; */

`;

const Close = styled(Button)<{url: string | undefined, open: boolean}>`

	position: absolute;
	right:0;top:0;

	height: 30px;
	width: 30px;

	margin: ${Theme.responsive.whitespace.sm}px;


	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center 5%;

	
	transition: all 0.3s;
	visibility: ${p => p.open ? 'visible' : 'hidden'};
	opacity: ${p => p.open ? '1' : '0'};
	/* transition-delay: 0.5s; */

`;

//#endregion

//#region types

interface SlidingPanelProps {
	open: boolean;
	close: () => void; 
	activePanel: string | undefined;
} 

//#endregion

//#region data 

const panels : {[key : string]: FC<{close: () => void, open: boolean}>} = {
	'search' : SearchPanel,
	'navigate' : NavigatePanel,
};

export default SlidingPanel;