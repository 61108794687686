import { PartialItem } from '@directus/sdk';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { directus, FAQTranslation, limiter } from '../../../../utilities/directus';

import { useTextContext } from '../../../contexts/text/TextContext';
import { Header1, Header3 } from '../../../typography/Typography';
import NavigateBar from '../components/NavigateBar';
import PageContainer from '../components/PageContainer';
import Question from './Question';

const FAQ = () => {

	const {t} = useTextContext();
	const [faqData, setFaqData] = useState<PartialItem<FAQTranslation[]>>([]);
	const [selected, setSelected] = useState<number>();

	const getFaqData = async () => {	
		await limiter.removeTokens(1);
		await directus.items('faq_translations').readByQuery().then((a) => {
			setFaqData(a.data?a.data:[]);
		}).catch((e) => console.error(e, 'catching error'));
	};

	useEffect(() => {
		getFaqData();
	},[]);

	return(
		<>
			<NavigateBar/>
			<PageContainer style={{alignItems: 'center'}}>
				<Header1 style={{color: Theme.colors.primary}}>{t('faq.header.1')}</Header1><br/>
				<Header3>{t('faq.description.1')}</Header3>
				{faqData.map((faq, index) => {
					return(
						<Question key={index}
							setSelected={setSelected} 
							selected={selected} 
							faq={faq} index={index} />
					);
				})}					
			</PageContainer>
		</>
	);
};

// styled components

const Wrapper = styled.div`
	//width: 50%;
	text-align: center;
`;

export default FAQ;
