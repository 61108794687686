import { createContext, useContext, useEffect, useRef, useState  } from 'react';
import { Category, CategoryPage, directus, directus_url, limiter, Waypoint, WaypointTranslation } from '../../../utilities/directus';
import { CategoryData, ContextProps, FileData, GlobalContextProps, PageData, PageDataEntry } from './types';
import {Page} from '../../../utilities/directus';
import { ID, ManyItems, PartialItem } from '@directus/sdk';
import { useTextContext } from '../text/TextContext';

const GlobalContext = createContext<Partial<GlobalContextProps>>({});

const GlobalContextProvider = (props: ContextProps) => {

	const {language} = useTextContext();

	//#region Page & Category data

	const [pages, setPages] = useState<PageData | undefined>();
	const [categories, setCategories] = useState<CategoryData | undefined>();
	const [files, setfiles] = useState<FileData | undefined>();
	const [loadingState, setLoadingState] = useState<number>(-1); // -1 not initialized // 0 not page found // 1 loading // 2 loaded

	const getData = async () => {	

		//#region Page Data

		// page data object
		const pageData: PageData = {};

		await limiter.removeTokens(1);

		// get page data and store it inside the page data object
		await directus.items('pages').readByQuery().then((a) => {
			a.data && a.data.map((val) => {
				val.id ? pageData[val.id] = {data : val, translation : null , waypoints: []} : '';
			});
		}).catch((e) => console.error(e, 'catching error'));

		await limiter.removeTokens(1);

		// get page translations
		await directus.items('pages_translations').readByQuery({filter: {languages_code: {_eq: language}}}).then((a) => {
			a.data && a.data.map((val) => {
				val.pages_id ? pageData[val.pages_id].translation = val : '';
			});
		}).catch((e) => console.error(e, 'catching error'));

		// get waypoint data and store in temporary variables for later use.
		let wpb : PartialItem<Waypoint[]> | undefined | null;
		let wpbt : PartialItem<WaypointTranslation[]> | undefined | null;
		await limiter.removeTokens(1);
		await directus.items('waypoint_blocks').readByQuery().then((a) => {wpb = a.data;}).catch((e) => console.error(e, 'catching error'));
		await limiter.removeTokens(1);
		await directus.items('waypoint_blocks_translations').readByQuery({filter: {languages_code: {_eq: language}}}).then((a) => { wpbt = a.data; }).catch((e) => console.error(e, 'catching error'));
		
		
		// get all waypoint typed pages blocks relations and using the previously stored waypoint data, store data in pageData object.
		await limiter.removeTokens(1);
		await directus.items('pages_blocks').readByQuery({filter: {collection: {_eq: 'waypoint_blocks'}}, sort:['order']}).then((a) => {
			a.data && a.data.map((val) => {
				if(!wpb || !wpbt || !val || !val.item) return;
				val.pages_id && pageData[val.pages_id].waypoints.push({
					data: wpb.find(el => el && el.id && el.id.toString() === val.item), 
					translation: wpbt.find(el => el && el.waypoint_blocks_id && el.waypoint_blocks_id.toString() === val.item)
				});
			});
		}).catch((e) => console.error(e, 'catching error'));
		setPages(pageData);

		//#endregion

		//#region CategoryData

		// category data object
		const categoryData : CategoryData = {};

		// get categories page relations and store it inside a variable.
		let categoryPageRelation : PartialItem<CategoryPage>[];

		await limiter.removeTokens(1);
		await directus.items('categories_pages').readByQuery().then((a) => {
			if(a.data) categoryPageRelation = a.data; // limits to 4 page relations
		}).catch((e) => console.error(e, 'catching error'));

		// get categories, then fill the data object. Also extracts page relations.
		await limiter.removeTokens(1);
		await directus.items('categories').readByQuery().then((a) => {
			a.data && a.data.map((val) => {

				// extract page relations
				const extractedPageIds : ID[] = [];
				categoryPageRelation.map((relation) => relation.categories_id === val.id && relation.pages_id ? extractedPageIds.push(relation.pages_id) : '');
				
				// fill data object 
				val.id ? categoryData[val.id] = {data : val, translation : null, pages : extractedPageIds} : '';

			});
		}).catch((e) => console.error(e, 'catching error'));

		// get categories translations
		await limiter.removeTokens(1);
		await directus.items('categories_translations').readByQuery({filter: {languages_code: {_eq: language}}}).then((a) => {
			a.data && a.data.map((val) => {
				val.categories_id ? categoryData[val.categories_id].translation = val : '';
			});
		}).catch((e) => console.error(e, 'catching error'));
		setCategories(categoryData);

		//#endregion

		//#region File data
		await limiter.removeTokens(1);
		await directus.files.readByQuery({limit: -1}).then((a) => {
			const fileData : FileData = {};
			a.data && a.data.map((file) => {
				fileData[file.id] = file;
			});
			setfiles(fileData);
		});

	};

	const getFileURL = (id: string) => {
		if(!files) return undefined;
		return files[id] ? `${directus_url}assets/${files[id].filename_disk}&${files[id].modified_on}?access_token=j1bberj3bber` : 'file not found';
	};

	//#endregion


	//#endregion

	//#region General

	//#endregion

	//#region useEffects

	useEffect(() => {
		getData();
	},[]);

	//#endregion

	//#region Passed Props

	const passedFunctions = {
		getFileURL,
		setLoadingState
	};

	const passedValues = {
		loadingState,
		pages,
		categories,
		files,
	};		

	//#endregion

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
