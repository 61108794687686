import { PartialItem } from '@directus/sdk';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { directus, limiter, Page as PageType, PageTranslation } from '../../../../utilities/directus';
import { useTextContext } from '../../../contexts/text/TextContext';
import PageContent from './PageContent';
import StandardInfo from '../components/StandardInfo';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import Loading from './components/Loading';

const Page = () => {

	const params = useParams();
	const {language} = useTextContext();
	const {loadingState, setLoadingState} = useGlobalContext();

	const [page, setPage] = useState<PartialItem<PageType> | null | undefined>();
	const [pageTranslation, setPageTranslation] = useState<PartialItem<PageTranslation> | null | undefined>();


	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		if(!page || page.id?.toString() !== params.id){
			initialize();
		}
	},[params]);

	useEffect(() => {

		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		if(loadingState === 2 && params.st === undefined){
			swc.scrollTo(0,0);
		}
	},[loadingState]);

	const initialize = async () => {
		await limiter.removeTokens(1);
		await directus.items('pages').readByQuery({filter:{id: {_eq: params.id}}}).then((a) => { // add filter for status
			setPage(a && a.data && a.data[0]);
			if(a.data && a.data?.length < 1){
				setLoadingState && setLoadingState(0);
			}
			else{
				setLoadingState && setLoadingState(1);
			}
		}).catch((e) => console.error(e, 'catching error'));
	};

	const getTranslation = async () => {
		if(page){
			await limiter.removeTokens(1);
			await directus.items('pages_translations').readByQuery({filter:{
				pages_id:{_eq: page.id}, // id
				languages_code: {_eq: language} // languages code
			}}).then((a) => {
				setPageTranslation(a && a.data && a.data[0]);
			}).catch((e) => console.error(e, 'catching error'));
		}
	};

	useEffect(() => {
		if(!page) return;
		else if(page){
			getTranslation();
		}
	}, [page]);

	const { getFileURL } = useGlobalContext();

	return (
		<Container>
			{ page && pageTranslation &&
				<>	
					<StandardInfo title={pageTranslation.title} description={pageTranslation.description} img={getFileURL && page.image && getFileURL(page.image)} />
					<PageContent pageId={page.id} blocks={page.blocks} setLoadingState={(state: number) => setLoadingState && setLoadingState(state) }/>
				</>
			}
			{ (loadingState === 1) ? 
				<Loading/>
				: loadingState === 0 ?
					<>
				page not found
					</>
					:
					''
			}
		</Container>

	);
};

// styled components

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export default Page;
