import reactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Theme from '../../../../../../style/theme';

export const StyledReactMarkdown = styled(reactMarkdown)<{themeId?: number}>`


	& > p > a {
		color: ${Theme.colors.secondary}!important;
	}

	color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralDark : 
			p.themeId === 1 ? Theme.colors.neutralDark :
				p.themeId === 2 ? Theme.colors.neutralLight : 
					''};

		& > h1, h2, h3 {
		color: ${p => 
		p.themeId === 0 ? Theme.colors.primary : 
			p.themeId === 1 ? Theme.colors.primary :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};
		}
		& > ul{
			& > li{
				margin-top: ${Theme.responsive.whitespace.sm/2}px;
			}: 
		}

`;