import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import StandardInfo from '../components/StandardInfo';
import Question from '../page/blocks/components/Question';
import Content from '../page/blocks/components/Content';
import { Content as ContentType, directus, limiter } from '../../../../utilities/directus';
import { PartialItem } from '@directus/sdk';
import { useEffect, useState } from 'react';
import { useTextContext } from '../../../contexts/text/TextContext';
import HomeContentInformation from './components/HomeContentInformation';

const Home = () => {
	const {categories, pages, getFileURL} = useGlobalContext();
	const [ContentBlock, setContentBlock] = useState<PartialItem<ContentType>>();
	const {t} = useTextContext();

	const getContentBlock = async () => {	
		await limiter.removeTokens(1);
		await directus.items('content_blocks').readByQuery({
			filter:{id:	{_eq: 93}}})//only get the block we need on the homepage
			.then((a) => {
				setContentBlock(a.data?a.data[0]:{});
			}).catch((e) => console.error(e, 'catching error'));
	};

	useEffect(() => {
		getContentBlock();
	},[]);

	return (<>
		<StandardInfo
			img={getFileURL && getFileURL('419f3f0d-8ab6-439c-92f5-474fedcae3e2')}
			title={t('homepagetitle')} 
			description={t('homepagedescription')}/>
		<Container>
			{ContentBlock && <Content blockData={{
				id: ContentBlock.id ? ContentBlock.id : 93,//93 is the id we need 
				translations: ContentBlock.translations ? ContentBlock.translations : 80,//80 is the right translation 
				image: ContentBlock.image ? ContentBlock.image : '',
				image_alignment: ContentBlock.image_alignment ? ContentBlock.image_alignment : 'left',
				image_size: ContentBlock.image_size ? ContentBlock.image_size : 'twothirds',
				title_size: ContentBlock.title_size ? ContentBlock.title_size : 'fullwidth',
				theme: ContentBlock.theme ? ContentBlock.theme : 0
			}}></Content>}
			<StyledImg url={getFileURL && getFileURL('3d9b8742-50e5-4c75-b8f3-0e55c82b7739')}></StyledImg>
			<Question blockData={{id: 12, translations: 12}}></Question>
			<HomeContentInformation/>
		</Container>
	</>);
};

// styled component

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm}px ${Theme.responsive.whitespace.sm}px;
		padding-top: 0;

    };
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px ${Theme.responsive.whitespace.lg * 2}px;
		padding-top: 0;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl / 2}px ${Theme.responsive.whitespace.xl * 3}px;
		padding-top: 0;
    };
`;

const StyledImg = styled.div<{url: string | undefined}>` 

	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	height: 100px;

	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm/2}px 0 ;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg/2}px 0;
    };
`;

export default Home;
