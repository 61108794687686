import { BrowserRouter as Router} from 'react-router-dom';
import ContextProvider from './components/contexts/ContextProvider';
import Layout from './components/layouts/Layout';
import Routing from './components/routing/Routing';
import CustomThemeProvider from './style/CustomThemeProvider';

function App() {
	return (
		<>
			<ContextProvider>
				<CustomThemeProvider>
					<Router>
						<Layout>
							<Routing/>
						</Layout>
					</Router>
				</CustomThemeProvider>
			</ContextProvider>
		</>
	);
}

export default App;
