import styled from 'styled-components';
import Theme from '../../../../../../style/theme';

const BlockContainer = styled.div`

	position: relative;

	/* border: red 2px solid; */
	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm}px 0 ;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px 0;
    };

`;

export default BlockContainer;