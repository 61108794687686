import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../../../style/theme';
import { BasicLink } from '../../../../../basic/buttons/BasicLink';
import Button from '../../../../../basic/buttons/Button';
import { useGlobalContext } from '../../../../../contexts/global/GlobalContext';
import { useTextContext } from '../../../../../contexts/text/TextContext';
import { Header1, Header2 } from '../../../../../typography/Typography';

const NavigatePanel = (props: {close: () => void, open: boolean}) => {

	const { t } = useTextContext();
	const { categories, pages, getFileURL } = useGlobalContext();

	const [selectedCategory, setSelectedCategory] = useState<number | undefined>();

	const onClose = () => {
		props.close();
	};

	const onOpen = () => {
		setSelectedCategory(undefined);
	};

	useEffect(() => {
		if(props.open)
			onOpen();
	},[props.open]);

	return (
		<>

			{/* Heading  */}
			<div style={{borderLeft: `solid 5px ${Theme.colors.neutralLight}`, paddingLeft: '0.5rem'}}>
				<Header1>{t('layout.navigation.mobiletitle')}</Header1>
			</div>

			{/* All categories */}
			{ categories && selectedCategory === undefined && Object.values(categories).map((category, index) => 
				<Fragment key={`categoriesmobile-${index}`}>
					<Category onClick={() => setSelectedCategory(index)}>
						<Header2>{category?.translation?.name}</Header2>
						<Arrow url={getFileURL && getFileURL('e4ccf404-ac26-40ac-b2b3-af84b291b0c2')} style={{marginLeft: 'auto'}} />
					</Category>
					{ pages && category.pages.map((pageId, index) => 
						<Pages key={`mobilecategory-pages-${pageId}-${index}`}>
							<BasicLink onClick={() => onClose()} to={`/page/${pageId}`}>{pages[pageId].translation?.title}</BasicLink>
						</Pages>
					)}
				</Fragment>
			)}
			{/* Selected category with waypoints*/}
			{ categories && (selectedCategory !== undefined) && 
				<>
					<Category onClick={() => setSelectedCategory(undefined)}>
						<Arrow url={getFileURL && getFileURL('e4ccf404-ac26-40ac-b2b3-af84b291b0c2')} style={{marginRight: 'auto', transform: 'scaleX(-1)'}}  />
						<Header2>{Object.values(categories)[selectedCategory]?.translation?.name}</Header2>
					</Category>
					{ pages && Object.values(categories)[selectedCategory].pages.map((pageId, index) => 
						<>

							<Pages key={`mobilecategory-pages-${pageId}-${index}`}>
								<Header2><BasicLink onClick={() => onClose()} to={`/page/${pageId}`}>{pages[pageId].translation?.title}</BasicLink></Header2>
							</Pages>
							{ pages[pageId].waypoints.map((waypoint, index) => 
								<Waypoints key={`mobilecategory-waypoints-${pageId}-${index}`}>
									<BasicLink  onClick={() => onClose()}  to={`/page/${pageId}/${index}`}>{waypoint.translation?.name}</BasicLink>
								</Waypoints>
							)}
						</>
					)}
				</>
			}
		</>
	);
};

// styled components

const Category = styled(Button)`

	width: 100%;
	margin-bottom: 10px;
	margin-top: ${Theme.responsive.whitespace.sm}px;

	display: flex;
	align-items: stretch;

	background: 0;
	color: white;

	& > h2 {
		font-weight: normal;
	}

`;

const Arrow = styled.div<{url: string | undefined}>`
	width : 1rem;

	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

`;

const Pages = styled.div`
	margin-left: 20px;
	padding: 10px 0;
	font-size: 0.9rem;

	& > h2 {
		font-weight: normal;
	}
`;

const Waypoints = styled.div`
	margin-left: 40px;
	padding: 10px 0;
	font-size: 0.9rem;
`;

export default NavigatePanel;