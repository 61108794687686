import styled from 'styled-components';
import Theme from '../../../../style/theme';

const PageContainer = styled.div`
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	/* align-items: center; */

	/* text-align: center; */

	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm}px ${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px ${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl / 2}px ${Theme.responsive.whitespace.xl * 3}px;
    };
`;

export default PageContainer;