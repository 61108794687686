import styled from 'styled-components';
import Button from './Button';
import { useGlobalContext } from '../../contexts/global/GlobalContext';

const Close = (props: { onClick: () => void}) => {

	const {getFileURL} = useGlobalContext();

	return (
		<StyledButton onClick={() => props.onClick()} url={getFileURL && getFileURL('fd2ac433-a894-4059-adf9-3cc5fa74ae45')}/>
	);
};

const StyledButton = styled(Button)<{url: string | undefined}>`
	background-color: rgb(0,0,0,0);
    background-image: url(${p => p.url});
    background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

    height: 20px;
    width: 20px;
`;

export default Close;