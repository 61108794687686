import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { useTextContext } from '../../../contexts/text/TextContext';
import { Header1 } from '../../../typography/Typography';
import NavigateBar from '../components/NavigateBar';
import PageContainer from '../components/PageContainer';
import { StyledReactMarkdown } from '../page/blocks/components/StyledMarkdown';

const Koppelvlakken = () => {
	const {t} = useTextContext();
	return(
		<>
			<NavigateBar/>
			<PageContainer>
				<Header1 style={{color: Theme.colors.primary}}>Koppelvlakken</Header1><br/>
				<StyledReactMarkdown>{t('Koppelvlakken.tekst', true)}</StyledReactMarkdown>
			</PageContainer>
		</>
	);
};

// styled components

const Wrapper = styled.div`
	text-align: center;
`;

export default Koppelvlakken;
