import styled from 'styled-components';
import Theme from '../../../../../style/theme';

const Loading = () => {
	return (
		<Container>
			<StyledSpinner viewBox="0 0 50 50">
				<circle
					className="path"
					cx="25"
					cy="25"
					r="20"
					fill="none"
					strokeWidth="4"
				/>
			</StyledSpinner>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	left:0;right:0;top:0;bottom:0;

	background-color: ${Theme.colors.primary};
	z-index: 1000;
`;

  
const StyledSpinner = styled.svg`
	position: sticky;
	left: 50%;top:50%;
	transform: translate(-50%, -50%);
	animation: rotate 2s linear infinite;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
	
	& .path {
	  stroke: white;
	  stroke-linecap: round;
	  animation: dash 1.5s ease-in-out infinite;
	}
	
	@keyframes rotate {
	  100% {
		transform:  translate(-50%, -50%), rotate(360deg);
	  }
	}
	@keyframes dash {
	  0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	  }
	  50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	  }
	  100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	  }
	}
`;

export default Loading;
