
const Theme = {
	colors:{
		primary:  '#0b634e', // GGB groen
		primaryLight: '#d9e7e4',
		primaryDark: '#1a2e27',

		secondary: '#32b4b4', // Cyaan-ish
		secondaryLight: '#63C1C1',

		neutral: 'grey',
		neutralDark: 'black',
		neutralLight: 'white'
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace : {
			xs: 25,
			sm: 25,
			lg: 50,
			xl: 100,
		}
	}
};

export default Theme;
