import { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { PageBlockProps } from '../../PageContent';
 
const Waypoint = forwardRef<any, any>((props, ref) => {

	useEffect(() => {
		// eslint-disable-next-line react/prop-types
		props.onLoaded && props.onLoaded();	
	},[]);

	return (  
		<Container ref={ref}/>
	);
});

// styled component

const Container = styled.div`
	height: 0px;
	width: 100%;
	/* outline: 10px red solid; */
`;
 
export default Waypoint;