import { useRoutes } from 'react-router-dom';
import Home from './routes/home/Home';
import Page from './routes/page/Page';
import FAQ from './routes/FAQ/FAQ';
import Koppelvlakken from './routes/koppelvlakken/Koppelvlakken';
import Contact from './routes/contact/Contact';

const Routing = () => {

	const Route = () => useRoutes([
		{ path: '/', element: <Home/>},
		{ path: '/home', element: <Home/>},
		{ path: '/page/:id', element: <Page/>},
		{ path: '/page/:id/:st', element: <Page/>},
		{ path: '/FAQ', element: <FAQ/>},
		{ path: '/koppelvlakken', element: <Koppelvlakken/>},
		{ path: '/contact', element: <Contact/>},
	]);

	return (	
		<>
			<Route/>
		</>
	);
};

export default Routing;