import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../../../style/theme';
import { BasicLink } from '../../../../../basic/buttons/BasicLink';
import { useGlobalContext } from '../../../../../contexts/global/GlobalContext';
import { PageDataEntry } from '../../../../../contexts/global/types';
import { useTextContext } from '../../../../../contexts/text/TextContext';
import { Header1, Header2, Paragraph } from '../../../../../typography/Typography';

const SearchPanel = (props: {close: () => void, open: boolean}) => {

	const {pages, getFileURL} = useGlobalContext();
	const { t } = useTextContext();

	const [searchField, setSearchField] = useState('');
	const [filteredPages, setFilteredPages] = useState<PageDataEntry[]>();

	useEffect(() => {
		filterPages();
	},[searchField]);

	useEffect(() => {
		setSearchField('');
	},[props.open]);
	
	const filterPages = () => {
		setFilteredPages(pages && Object.values(pages).filter((value) => {		
			return (
				value.translation?.title && value
					.translation
					.title
					.toLowerCase()
					.includes(searchField.toLowerCase()) 
				||
				value.translation?.description && value
					.translation
					.description
					.toLowerCase()
					.includes(searchField.toLowerCase())
				||
				value.translation?.search_tags && value
					.translation
					.search_tags
					.includes(searchField)
				||					
				value.translation?.short_description && value
					.translation
					.short_description
					.toLowerCase()
					.includes(searchField.toLowerCase())
			);
		}
		));
	}; 

	const handleChange = (e: any) => {
		setSearchField(e.target.value);
	};

	return (
		<>
			{/* Heading */}
			<div style={{borderLeft: `solid 5px ${Theme.colors.neutralLight}`, paddingLeft: '0.5rem'}}>
				<Header1>{t('layout.navigation.search.mobiletitle')}</Header1>
			</div>

			{/* Search field */}
			<SearchBarContainer>
				<SearchBarIcon url={getFileURL && getFileURL('c53bf9fb-997b-4ecb-9311-80cbe35fcd1c')}/>
				<SearchBar 
					placeholder = {`${t('layout.navigation.search.placeholder')}`} 
					onChange = {handleChange}
					value = {searchField}
					maxLength={100}
				/>
			</SearchBarContainer>

			{/* Search results */}
			{ searchField !== '' && 
				<SearchResults>
					{ filteredPages && filteredPages.length > 0 && filteredPages.slice(0, 5).map((page, index) =>
						<SearchResult key={`searchresultmobile-${page.data.id}-${index}`}>
							<BasicLink to={`/page/${page.data.id}`} onClick={() => props.close()}>
								<Header2>{page.translation?.title}</Header2>
								<Paragraph>{page.translation?.short_description}</Paragraph>
							</BasicLink>
						</SearchResult>
					)}
				</SearchResults>
			}

		</>
	);
};

// styled components

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchBar = styled.input`

	box-sizing: border-box;
	width: 100%;

	border-radius: 1rem;
	border: 0;
	outline: 0;

	background-color: rgb(255,255,255, 0.5);
	color: ${Theme.colors.neutralLight};

	padding: 5px 40px 5px 15px; 
	margin: ${Theme.responsive.whitespace.sm}px 0;

	::placeholder {
		color: ${Theme.colors.neutralLight};;
	}

	font-size: 1.1rem;

`;

const SearchBarIcon = styled.div<{url: string | undefined}>`
	position: absolute;

	right:0;
	top:50%;
	transform: translate(-50%, -50%);

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	height: 1.4rem;
	width: 1.4rem;
`;

const SearchResults = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: ${Theme.responsive.whitespace.sm}px; */
`;

const SearchResult = styled.div`
	margin-bottom: 20px;
`;

export default SearchPanel;