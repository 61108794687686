import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import Theme from '../../../../style/theme';
import Button from '../../../basic/buttons/Button';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import Categories from './Categories';
import Search from './search/Search';

const Navigation = () => {

	const [activeDropdown, setActiveDropdown] = useState<string | undefined>();

	const toggleDropdown = (label: string | undefined) => {
		setActiveDropdown(label);
	};

	const {getFileURL, files, categories, pages} = useGlobalContext();

	useEffect(() => {

		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		swc.addEventListener('scroll', scrollListener, { passive: true });

		return () => {
			swc.removeEventListener('scroll',scrollListener);
		};
	},[]);

	const [logoURL, setLogoURL] = useState<string | undefined>();

	useEffect(() => {
		setLogoURL(getFileURL && getFileURL('0303e32c-5f41-443d-b33d-1706cc781700'));
	},[files]);

	const [scrollPosition, setScrollPosition] = useState<boolean>(true);
	const scrollListener = (e: any) => {
		const position = e.srcElement.scrollTop;
		setScrollPosition(position === 0 ? true : false);
	};

	return (
		<>
			{ files && categories && pages &&
				<Container seethrough={activeDropdown ? false : scrollPosition}>
					<Logo to={'/home'} img={logoURL}></Logo>
					<Categories setActiveDropdown={(label) => toggleDropdown(label)} activeDropdown={activeDropdown}/>
					<Search setActiveDropdown={(label) => toggleDropdown(label)} activeDropdown={activeDropdown}/>
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`
	background: ${(p:{seethrough: boolean}) => p.seethrough ? 'none' : Theme.colors.primary};

	display: flex;
	align-items: center ;
	/* overflow-x: hidden; */
	/* position: sticky;
	left:0;
	top:0;
	right:0; */

	position: relative;

    //mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        padding: ${Theme.responsive.whitespace.sm / 3}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 3}px;
    };

	z-index: 10;

	color: ${Theme.colors.neutralLight};

	transition: background-color .25s;

`;

const Logo = styled(Link)<{img: string | undefined}>`
	flex-shrink: 0;

	background-color: rgb(0,0,0,0);
	background-image: url(${p => p.img});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	height: calc(104px / 2);
	width: calc(366px / 2);

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		
        /* margin-right: ${Theme.responsive.whitespace.sm / 2}px; */
		height: calc(104px / 3);
		width: calc(366px / 3);	
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		/* margin-right: ${Theme.responsive.whitespace.lg / 2}px; */
		height: calc(104px / 2);
		width: calc(366px / 2);
    };
`;

// types

export interface dropdownParentProps {
	setActiveDropdown: (label: string | undefined) => void,
	activeDropdown: string | undefined,
}

export {Logo};

export default Navigation;