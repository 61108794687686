import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Theme from '../../style/theme';
import Footer from './components/Footer';
import MobileNavigation from './components/navigation/mobile/MobileNavigation';
import Navigation from './components/navigation/Navigation';
import { useGlobalContext } from '../contexts/global/GlobalContext';

const Layout = (props: {children: ReactNode}) => {

	const {loadingState} = useGlobalContext();
	const [mobile, setMobile] = useState(window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).matches);

	useEffect(() => { 
		window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).addEventListener('change', (e) => setMobile(e.matches));
		return (() => {
			window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).removeEventListener('change', (e) => setMobile(e.matches));
		});
	}, []);

	return (
		<>
			<Container>
				<NavigationContainer>
					{/* media query with mobile UI */}
					{mobile ?
						<MobileNavigation/>
						:
						<Navigation/>
					}
				</NavigationContainer>
				<ScrollingContentWindow id={'scrollingContentWindow'} state={loadingState != undefined ? loadingState : 0}>
					<Content >
						<div style={{flexGrow: 1}}>{ props.children }</div>
						<Footer/>
					</Content>
				</ScrollingContentWindow>
			</Container>
		</>

	);
};

// styled components

const ScrollingContentWindow = styled.div<{state: number}>`
	flex-grow: 1; 
	overflow: auto;

	${ p => p.state !=2 && css`
		-ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none; /* for Firefox */
		overflow-y: scroll; 
		::-webkit-scrollbar {
			display: none; /* for Chrome, Safari, and Opera */
		}
	`}
`;


const Container = styled.div`
	display: flex;
	flex-direction: column;
	/* min-height: 100%; */
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	
	/* flex-grow: 1; */
	/* overflow-y: auto; */
`;

const NavigationContainer = styled.div`
	height:0;
	position: sticky;
	left:0;top:0;right:0;
	z-index: 5;
`;

export default Layout;