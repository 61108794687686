import styled from 'styled-components';

const Button = styled.button`
	border:none;
	outline:none;
	margin: 0;
	padding: 0;

	&:hover, :focus {
		text-decoration: underline;
	}

	&:focus {
		outline: solid 2px black;
	}

	cursor: pointer;
`;

export default Button;