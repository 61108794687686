import { ID, PartialItem } from '@directus/sdk';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Theme from '../../../../../../style/theme';
import { directus, limiter, Outlined as OutlinedProps, OutlinedContent, OutlinedContentTranslation } from '../../../../../../utilities/directus';
import { useTextContext } from '../../../../../contexts/text/TextContext';
import {Header2 } from '../../../../../typography/Typography';
import { PageBlockProps } from '../../PageContent';
import BlockContainer from './BlockContainer';
import { StyledReactMarkdown } from './StyledMarkdown';


const Outlined = (props: {blockData: OutlinedProps} & PageBlockProps) => {
	const [dataState, setDataState] = useState<number>(0); // 0 === not begun gathering data || 1 === started gathering data || 2 === gathered data
	const [outlinedData, setOutlinedData] = useState<PartialItem<OutlinedContentTranslation[]>>([]);
	const {language} = useTextContext();

	const getOutlinedData = async () => {
		const relationsIds: ID[] = [];	
		await limiter.removeTokens(1);
		await directus.items('outlined_blocks_outlined_block_content').readByQuery({
			filter:{id:	{_in: props.blockData.content}}})
			.then((a) => {
				if(a.data) a.data.map((relation) => {
					if(relation.outlined_block_content_id) relationsIds.push(relation.outlined_block_content_id);
				});
			}).catch((e) => console.error(e, 'catching error'));
		const translationIds: ID[] =[];
		await limiter.removeTokens(1);
		if(relationsIds.length > 0) await directus.items('outlined_block_content').readByQuery({filter:{id:{_in: relationsIds}}}).then((a) => {
			if(a.data) a.data.map((data: any) => {
				if(data.translations[0]) translationIds.push(data.translations[0]);
			});
		}).catch((e) => console.error(e, 'catching error'));
		await limiter.removeTokens(1);
		if(relationsIds.length > 0) await directus.items('outlined_block_content_translations').readByQuery({
			filter:{id:{_in: translationIds}, languages_code: {_eq: language}}})
			.then((a) => {
				setDataState(2);
				if(a.data) setOutlinedData(a.data);
			}).catch((e) => console.error(e, 'catching error'));
	};

	useEffect(() => {
		getOutlinedData();
	},[]);

	useEffect(() => {
		if(dataState === 2) props.onLoaded && props.onLoaded();
	},[dataState]);

	return (  
		<BlockContainer>
			<Backdrop themeId={props.blockData.theme}/>
			<Wrapper themeId={props.blockData.theme}>		
				{outlinedData.map((outline, index) => {
					return(<div key={index}>
						<Header2 style={props.blockData.theme != 2 ? {color: Theme.colors.primary} : {color: 'white'}}>{outline?.title}</Header2>
						<Columns>
							{outline && <><StyledReactMarkdown themeId={props.blockData.theme}>{outline.column_1 ? outline.column_1 : ''}</StyledReactMarkdown>
								<StyledReactMarkdown themeId={props.blockData.theme}>{outline.column_2 ? outline.column_2 : ''}</StyledReactMarkdown></>}
						</Columns>
					</div>	);
				})}	
			</Wrapper>
		</BlockContainer>
	);
};

//styled components
const Wrapper = styled.div<{themeId: number}>`
	border: 2px solid ${Theme.colors.secondary};

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm/2+'px'} ${Theme.responsive.whitespace.sm+'px'};
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
        padding: ${Theme.responsive.whitespace.lg/2+'px'} ${Theme.responsive.whitespace.lg+'px'};
    };
`;
const Columns = styled.div`
	display: flex;
	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		flex-direction: column;
		gap: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		flex-direction: row;
		gap: ${Theme.responsive.whitespace.lg}px;
		& > div {
			width: 50%;
		}
    };`;

const Backdrop = styled.div<{themeId: number}>`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: 0 -${Theme.responsive.whitespace.xl * 3}px;
    };

	background-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.primaryLight :
				p.themeId === 2 ? Theme.colors.primary : 
					''};

`;
 
export default Outlined;