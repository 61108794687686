import { ID, PartialItem } from '@directus/sdk';
import { watch } from 'fs';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { directus, limiter, PagesBlocks,} from '../../../../utilities/directus';
import Button from './blocks/components/Button';
import Content from './blocks/components/Content';
import Outlined from './blocks/components/Outlined';
import Question from './blocks/components/Question';
import Summary from './blocks/components/Summary/Summary';
import Waypoint from './blocks/components/Waypoint';
import ScrollTracking from './ScrollTracking';

const PageContent = (props: {pageId: ID | undefined , blocks: (ID | undefined)[] | undefined, setLoadingState: (state: number) => void}) => {

	const params = useParams();

	useEffect(() => {

		initialize();

		window.addEventListener('resize', onWindowResize);

		return (() => {
			window.removeEventListener('resize', onWindowResize);

		});

	},[]);

	useEffect(() => {
		initialize();
		setInitialScroll(false);
		setLoaded(false);
		setLoadCheckCount(0);
	},[props.pageId]);

	const initialize = async () => {
		if(props.pageId){
			await limiter.removeTokens(1);
			await directus.items('pages_blocks').readByQuery({
				filter:{pages_id: {_eq: props.pageId}},
				sort: ['order']
			}).then((a) => {getPageBlocks(a.data);}).catch((e) => console.error(e, 'catching error'));
		}
	};
	
	
	//#region waypoints

	const waypoints = useRef<any>([]);
	const [initialScroll, setInitialScroll] = useState<boolean>(false);
	const scrollTo = (index: number) => {

		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		if(index === -1){
			swc.scrollTo(0,0); // scrolls with an offset equal to the height of the navbar (roughly)
		}

		if(!waypoints.current[index])return;


		swc.scrollTo({top: waypoints.current[index].offsetTop + (window.innerHeight * 0.75) - 100, behavior: 'smooth'}); // scrolls with an offset equal to the height of the navbar (roughly)
	};  

	useEffect(() => {if(params.st !== undefined && loaded && props.pageId && props.pageId.toString() === params.id){ scrollTo(parseInt(params.st));}},[params]); // scroll on param change

	const [trackingPoints, setTrackingPoints] = useState<number[]>([]);
	const createTrackingPoints = () => {
		const a : number[] = [];
		waypoints.current.map((value : HTMLElement, index : number) => {
			a[index] = value.offsetTop + (window.innerHeight * 0.75);
		});
		setTrackingPoints(a);
	};
	const [windowResized, setWindowResize] = useState<boolean>(false);
	const onWindowResize = () => {
		setWindowResize(true);
	};
	useEffect(() => {
		if(windowResized){
			createTrackingPoints();
			setWindowResize(false);
		}
	},[windowResized]);

	
	//#endregion

	//#region loading

	const [loaded, setLoaded] = useState(false);
	const [loadCheckCount, setLoadCheckCount] = useState(0);

	const onLoaded = (i: number) => {
		setLoadCheckCount(a => a + 1);
	};

	const checkLoaded = () => {
		let check = true;
		if(!pageBlocks) return false;
		if(loadCheckCount !== pageBlocks.length){
			check = false;
		}

		return check;
	};

	useEffect(() => {
		const check = checkLoaded();
		if(check){
			setLoaded(true);
		}
	}, [loadCheckCount]);

	useEffect(() => {
		if(loaded){
			createTrackingPoints();
			props.setLoadingState(2);
			if(params.st !== undefined){
				scrollTo(parseInt(params.st));
			}
		}
		else{
			scrollTo(-1);
		}
	}, [loaded]);

	//#endregion

	//#region Data manipulation

	// organized block data for easy mapping within the render function
	const [pageBlocks, setPageBlocks] = useState<BlockEntry[]>();

	const getPageBlocks = async (blockData: BlockData) => {
		//
		if(!blockData || blockData?.length < 0){
			setPageBlocks(undefined);
			setLoaded(true);
			return;
		}

		const blocks: { [key: string]: ID[]} = {};

		blockData?.map((block) => {
			if(block && block.collection && block.item){
				!blocks[block.collection] ? blocks[block.collection] = [] : '';
				blocks[block.collection].push(block.item);
			}
		});

		const blockCollections: { [key: string]: PartialItem<PageBlock>[] } = {};
		await limiter.removeTokens(1);
		await Promise.all(Object.keys(blocks).map(async (collection) => {
			if(blocks[collection].length > 0){
				await directus.items(collection).readByQuery({
					filter:{id: {_in: blocks[collection]}}
				}).then((a) => a.data ? blockCollections[collection] = a.data : '').catch((e) => console.error(e, 'catching error'));
			}
		}));

		const newPageBlocks : BlockEntry[] = []; 

		blockData?.map((block) => {
			if(block.collection){
				const newBlock = blockCollections[block.collection].find((item) => {
					return item.id?.toString() === block.item;
				});
				if(newBlock){
					newPageBlocks.push({data:newBlock, collection: block.collection});
				}
			}
		});
		waypoints.current = [];

		setPageBlocks(newPageBlocks);

	};

	useEffect(() => {
		if(!params.st) return;
		// if(!initialScroll){
		// 	setInitialScroll(true);
		// 	setTimeout(()=>{
		// 		params.st && scrollTo(parseInt(params.st));
		// 	}, 100);
		// }
	},[pageBlocks]);

	//#endregion

	return(
		<Container>
			{ pageBlocks &&
				<>
					{ trackingPoints && loaded &&
						<ScrollTracking 
							trackingPoints={trackingPoints}
							scrollTo={(i) => scrollTo(i)}
						/> 
					}
					{ pageBlocks?.map((block, index) => {
						const Component = blockComponents[block.collection];
						if(!Component)  return null;
						else{
							return (
								<Fragment key={`block-${index}-${block.data?.id}`}> 
									{ block.collection !== 'waypoint_blocks' ?
										<Component blockData={block.data} onLoaded={() => onLoaded(index)}/>
										:
										// an exception for waypoint because waypoint needs to be referenced to auto scroll.
										// this makes it so that Waypoint needs forwardRef syntax and the others do not.
										<Waypoint 
											ref={(el: any) => el && !waypoints.current.includes(el) ? waypoints.current[waypoints.current.length] = el : ''}
											onLoaded={() => onLoaded(index)} 
										/>
									}
								</Fragment>
							);
						}
					})}
				</>
			}
		</Container>
	);
};

// components

const blockComponents : {[key: string]: any} =  {
	'content_blocks' : Content,
	'summary_blocks' : Summary,
	'waypoint_blocks': Waypoint,
	'outlined_blocks': Outlined,
	'question_blocks': Question,
	'button_blocks': Button,
};

// styled components

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	@media (max-width: ${Theme.responsive.media.lg}){
		padding: 0 ${Theme.responsive.whitespace.sm}px;

    };
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: 0 ${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: 0 ${Theme.responsive.whitespace.xl * 3}px;
    };
`;

// types

type BlockData = PartialItem<PagesBlocks>[] | null | undefined;
type PageBlock = PartialItem<any>;
type BlockEntry = {data : PageBlock | null | undefined, collection: string};
export type PageBlockProps = {
	onLoaded? : () => void;
}

export default PageContent;