// src/components/Search.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../../style/theme';
import Close from '../../../../basic/buttons/CloseButton';
import { useGlobalContext } from '../../../../contexts/global/GlobalContext';
import { PageData, PageDataEntry } from '../../../../contexts/global/types';
import { useTextContext } from '../../../../contexts/text/TextContext';
import { Header1, Header2, Paragraph } from '../../../../typography/Typography';
import DropdownContainer from '../components/DropdownContainer';
import { dropdownParentProps } from '../Navigation';
import SearchResult from './SearchResult';


const Search = (props: dropdownParentProps) => {
	const {pages, getFileURL} = useGlobalContext();

	const [searchField, setSearchField] = useState('');
	const [filteredPages, setFilteredPages] = useState<PageDataEntry[]>();

	useEffect(() => {
		if(searchField){
			filterPages();
			props.setActiveDropdown('search');
		}
		else if(props.activeDropdown === 'search'){
			props.setActiveDropdown(undefined);
		}
	},[searchField]);

	useEffect(() => {
		if(props.activeDropdown !== 'search'){
			setSearchField('');
		}
	},[props.activeDropdown]);

	const filterPages = () => {
		setFilteredPages(pages && Object.values(pages).filter((value) => {		
			return (
				value.translation?.title && value
					.translation
					.title
					.toLowerCase()
					.includes(searchField.toLowerCase()) 
				||
				value.translation?.description && value
					.translation
					.description
					.toLowerCase()
					.includes(searchField.toLowerCase())
				||
				value.translation?.search_tags && value
					.translation
					.search_tags
					.includes(searchField)
				||					
				value.translation?.short_description && value
					.translation
					.short_description
					.toLowerCase()
					.includes(searchField.toLowerCase())
			);
		}
		));
	}; 

	const handleChange = (e: any) => {
		setSearchField(e.target.value);
	};

	const {t} = useTextContext();

	return (
		<Container>
			{/* Search Bar */}

			<div style={{marginLeft: 'auto', position: 'relative'}}>
				<SearchIcon url={getFileURL && getFileURL('c53bf9fb-997b-4ecb-9311-80cbe35fcd1c')}/>
				<SearchBar 
					placeholder = {`${t('layout.navigation.search.placeholder')}`} 
					onChange = {handleChange}
					value = {searchField}
					maxLength={100}
				/>
			</div>
			{/* Search Results in DropdownContainer */}
			{props.activeDropdown === 'search' && 
				<DropdownContainer style={{paddingBottom: '0'}}>
					<SubContainer>
						<Header1>{filteredPages && filteredPages?.length > 0 ? `${filteredPages.length} ${t('layout.navigation.search.title')}` : t('layout.navigation.search.noresults')}</Header1><br/>
						<Paragraph>{t('layout.navigation.search.present')}<b> {searchField}</b></Paragraph><br/>
						<SearchResults>
							{filteredPages && filteredPages.length > 0 && filteredPages.slice(0, 5).map((page, index) => (
								page.translation && <SearchResult key={index} page={page.translation} setActiveDropdown={(label: string) => props.setActiveDropdown(label)} />
							))}
						</SearchResults>
					</SubContainer>
					<Close onClick={() => setSearchField('')}/>
				</DropdownContainer>
			}
		</Container>
	);
};

// styled components

const Container = styled.div`
	margin: auto 0 auto auto;
`;

const SearchBar = styled.input`
	border-radius: 1rem;
	border: 0;
	outline: 0;

	background-color: rgb(255,255,255, 0.5);
	color: ${Theme.colors.neutralLight};

	padding: 5px 40px 5px 15px; 

	::placeholder {
		color: ${Theme.colors.neutralLight};;
	}

	font-size: 1.1rem;

`;

const SearchIcon = styled.div<{url: string | undefined}>`
	position: absolute;
	right:0;
	top:50%;
	transform: translate(-50%, -50%);

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	height: 1.4rem;
	width: 1.4rem;
`;

const SearchResults = styled.div`

	display: flex;
	flex-direction: column;

	overflow-y: auto;

	min-height: 100px;

	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm / 3}px;
	};

		//desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg / 3}px; 
	};


	& :last-child {
		@media (max-width: ${Theme.responsive.media.lg}){
			padding-bottom: ${Theme.responsive.whitespace.sm / 3}px;
    	};

		//desktop
		@media (min-width: ${Theme.responsive.media.lg}){
			padding-bottom: ${Theme.responsive.whitespace.lg / 3}px; 

		};
	}

`;

const SubContainer = styled.div`
	display: flex;
	flex-direction: column;


	flex-grow: 1;
	min-height: 100px;

	@media (max-width: ${Theme.responsive.media.lg}){
		padding-left: ${(366 / 3) + Theme.responsive.whitespace.sm}px; // calculation based on logo width, horizontal padding and left margin/padding of categories.
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding-left: ${(366 / 2) + Theme.responsive.whitespace.lg}px; // calculation based on logo width, horizontal padding and left margin/padding of categories.
    };
`;

export default Search;