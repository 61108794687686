import { ID, PartialItem } from '@directus/sdk';
import { ReactNode, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';
import Theme from '../../../../../../style/theme';
import { Content as _ContentProps, ContentTranslation, directus, limiter } from '../../../../../../utilities/directus';
import { useGlobalContext } from '../../../../../contexts/global/GlobalContext';
import { useTextContext } from '../../../../../contexts/text/TextContext';
import { Header1, Paragraph } from '../../../../../typography/Typography';
import { PageBlockProps } from '../../PageContent';
import BlockContainer from './BlockContainer';
import { StyledReactMarkdown } from './StyledMarkdown';
 
const Content = (props: {blockData: _ContentProps} & PageBlockProps) => {

	const [dataState, setDataState] = useState<number>(0); // 0 === not begun gathering data || 1 === started gathering data || 2 === gathered data
	const [translation, setTranslations] = useState<PartialItem<ContentTranslation> | null | undefined>();

	const {language} = useTextContext();
	const {getFileURL} = useGlobalContext();

	useEffect(() => {
		if(dataState === 0){
			getData();
		}
	},[]);

	const getData = async () => {
		setDataState(1);
		await limiter.removeTokens(1);
		await directus.items('content_blocks_translations').readByQuery({filter:{content_blocks_id: {_eq: props.blockData.id}, languages_code: {_eq: language}}}).then((a) => {
			a.data && setTranslations(a.data[0]);
		}).catch((e) => console.error(e, 'catching error'));
		setDataState(2);
	};

	useEffect(() => {
		if(dataState === 2) props.onLoaded && props.onLoaded();
	},[dataState]);


	return (  
		<BlockContainer>
			{ dataState === 2 && translation &&
				<>
					<Container>
						{ translation.title && props.blockData.title_size === 'fullwidth' &&
							<StyledHeader1 themeId={props.blockData.theme}>{translation.title}</StyledHeader1>
						}
						<Grid>
							<Column image={props.blockData.image} imageSize={props.blockData.image_size}>
								{ translation.title && props.blockData.title_size === 'fit' &&
								<StyledHeader1 style={{paddingBottom: Theme.responsive.whitespace.lg/3}} themeId={props.blockData.theme}>{translation.title}</StyledHeader1>
								}
								<StyledReactMarkdown themeId={props.blockData.theme}
									components={
										props.blockData.theme === 2 ? {p : ({...props}) => <StyledParagraph themeId={2}>{props.children}</StyledParagraph>} : {p : ({...props}) => <StyledParagraph>{props.children}</StyledParagraph>}
									}
								>
									{translation.paragraph ? translation.paragraph : ''}
								</StyledReactMarkdown>
							</Column>
							{ props.blockData.image &&
								<ImageColumn imageSize={props.blockData.image_size} imageAlignment={props.blockData.image_alignment}>
									<Image imageSize={props.blockData.image_size} url={getFileURL && getFileURL(`${props.blockData.image}`)}/>
								</ImageColumn>
							}
						</Grid>

					</Container>
					<Backdrop themeId={props.blockData.theme}/>
				</>
			}
		</BlockContainer>
	);
};

// styled components
const Container = styled.div`
	position: relative;
`;

const Backdrop = styled.div<{themeId: number}>`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.sm}px;
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 0 -${Theme.responsive.whitespace.lg * 2}px;
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: 0 -${Theme.responsive.whitespace.xl * 3}px;
    };

	background-color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralLight : 
			p.themeId === 1 ? Theme.colors.primaryLight :
				p.themeId === 2 ? Theme.colors.primary : 
					''};

`;

type ColumnProps = {
	image?: ID, 
	imageSize?: string,
	imageAlignment?: string
}

const Column = styled.div<ColumnProps>`


	grid-column: ${ p => {
		if(!p.image) return 'span 3';
		
		switch(p.imageSize){
		case 'fullwidth' : 
			return 'span 3';
			break;
		case 'twothirds' : 
			return 'span 1';
			break;
		case 'onethirds' : 
			return 'span 2';
			break;
		}
	}};

	& :first-child {
		margin: 0;
	}

	order: 1;



	@media (max-width: ${Theme.responsive.media.lg}){
        grid-column: 1 / span 3;
    };

	@media (min-width: ${Theme.responsive.media.lg}){
		order: 2;
    };
	
`;

const Grid = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-flow: dense;
	
	@media (max-width: ${Theme.responsive.media.lg}){
        grid-template-columns: 1fr;
		grid-gap: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		column-gap: ${Theme.responsive.whitespace.lg}px;
		grid-gap: ${Theme.responsive.whitespace.lg}px;
    };

`;

const StyledHeader1 = styled(Header1)<{themeId: number}>`
	color: ${p => 
		p.themeId === 0 ? Theme.colors.primary : 
			p.themeId === 1 ? Theme.colors.primary :
				p.themeId === 2 ? Theme.colors.primaryLight : 
					''};

	@media (max-width: ${Theme.responsive.media.lg}){
        margin-bottom: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-bottom: ${Theme.responsive.whitespace.lg}px;

    };
`;

const StyledParagraph = styled(Paragraph)<{themeId?: number}>`

	@media (max-width: ${Theme.responsive.media.lg}){
        margin: 10px 0;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: 10px 0;
    };

	color: ${p => 
		p.themeId === 0 ? Theme.colors.neutralDark : 
			p.themeId === 1 ? Theme.colors.neutralDark :
				p.themeId === 2 ? Theme.colors.neutralLight : 
					''};
`;

const Image = styled.div<{url:string | undefined, imageSize: string | undefined}>`

	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-position: top center;
	background-color: rgba(0,0,0,0);

	width: 100%;


	${ p => {
		switch(p.imageSize){
		case 'fullwidth' : 
			return css`
				height: 60vh;
				background-size: contain;

			`;
			break;
		case 'twothirds' : 
			return css`
				background-size: contain;
				aspect-ratio: 2/1;
				min-width: 200px;
				min-height: 200px;
			`;
			break;
		case 'onethirds' : 
			return css`
				aspect-ratio: 1/1;
				min-width: 200px;
				min-height: 200px;
				max-height: 500px;
				background-size: cover;
			`;
			break;
		}
		
	}}
`;


const ImageColumn = styled.div<ColumnProps>`
	grid-column: ${ p => {
		let startingColumn;
		let span;
		
		switch(p.imageSize){
		case 'fullwidth' : 
			span = 'span 3';
			break;
		case 'twothirds' : 
			span = 'span 2';
			break;
		case 'onethirds' : 
			span = 'span 1';
			break;
		}

		switch(p.imageAlignment){
		case 'left' : 
			startingColumn = '1';
			break;
		case 'right' : 
			if(p.imageSize === 'twothirds'){
				startingColumn = '2';
			}else{
				startingColumn = '3';
			}
			break;
		}
		return `${startingColumn ? startingColumn + ' / ' : ''} ${span}`;
	}};

	order: ${p => p.imageSize === 'fullwidth' ? '3' : p.imageAlignment === 'left' ? '1' : '2'};

	@media (max-width: ${Theme.responsive.media.lg}){
        grid-column: 1 / span 3;
    };
`;

 
export default Content;