import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { Header1, Paragraph } from '../../../typography/Typography';
// import ReactPlayer from 'react-player';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { useState } from 'react';

const StandardInfo = (props: {homepage?: boolean, img?: string | undefined, title: string | undefined, description: string | undefined}) => {

	const [videoStarted, setVideoStarted] = useState<boolean>(false);
	
	return (
		<Container img={props.img}>
			<NavigateBar/>
			{/* {props.homepage && <ReactPlayer width={'100%'} height={'75vh'} style={{ position: 'absolute'}}
				playIcon={<StartButton onClick={() => setVideoStarted(true)}/>} 
				url={'https://tqwi-p22-01.directus.app/assets/a1981b06-a252-4965-a317-5246b567b428.mp4'}>
			</ReactPlayer>} */}
			{!videoStarted && <TitleWrapper>
				<Header1>{props.title}</Header1>
				<Paragraph style={{marginTop: Theme.responsive.whitespace.sm+'px'}}>{props.description}</Paragraph>
			</TitleWrapper>}
			<NavigateBar/>
			<Backdrop/>
		</Container>
	);
};

//styled components

const NavigateBar = styled.div`
	background-color: ${Theme.colors.primary};
	@media (max-width: ${Theme.responsive.media.lg}){
		height: ${`calc(50px + ${(Theme.responsive.whitespace.sm / 3) * 2}px)`};
    };
	@media (min-width: ${Theme.responsive.media.lg}){
		height: ${50 + Theme.responsive.whitespace.lg / 3 + Theme.responsive.whitespace.lg / 3 +'px'};
    };
`;

const TitleWrapper = styled.div`
	text-align: center;

	@media (max-width: ${Theme.responsive.media.lg}){
		width: 60%;

    };
	@media (min-width: ${Theme.responsive.media.lg}){
		width: 40%;
    };

	z-index:2;
`;

const StartButton = styled.div`
	width: 50px;
	height: 50px;
	border: 5px solid white;
	border-radius: 50%;

	z-index:2;
	margin-top: 25px;
`;

const Container = styled.div<{img: string | undefined}>`

	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: center;
	
	min-height: 75vh;
	
	background-image: url(${p => p.img});
    background-size: cover;
	background-position: center;

	color: white;
	z-index:0;
	//padding: ${50 + Theme.responsive.whitespace.lg / 3 + Theme.responsive.whitespace.lg / 3 + 'px 0px 0px 0px'};
`;

const Backdrop = styled.div`

	left:0;right:0;top:0;bottom:0;
	position: absolute;
	width: 100%;
	z-index: 1;

	background-color: rgba(0, 0, 0, 0.4);
`;

export default StandardInfo;