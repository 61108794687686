import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

// eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		font-size: 1.1em;		
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		/* overflow: hidden; */
		background-color: ${Theme.colors.neutralLight};
		color: ${Theme.colors.neutralDark}; // replaced by typography
    	font-family: "Avenir";
	}

	body > * {
		box-sizing: border-box;
	}

	#root {
		height: 100%;
	}

	body, button, p, h1, h2, h3, input {
    	font-family: "Avenir";
	}
`;

export default GlobalStyle;
