import { Directus, ID } from '@directus/sdk';
import { RateLimiter } from 'limiter';

export const directus_url = 'https://tqwi-p22-01.directus.app/';
export const directus_auth_token = 'j1bberj3bber';

export const directus = new Directus<DirectusProps>(directus_url, {auth: {staticToken: directus_auth_token}});

export const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 800});

export type DirectusProps = {

	// Basic

	// Text

	text_translations: TextTranslation,

	// pages

	pages: Page,
	pages_blocks: PagesBlocks,
	pages_translations: PageTranslation,

	// categories

	categories: Category,
	categories_translations: CategoryTranslation,
	categories_pages: CategoryPage,

	// Page Blocks

	// summary

	summary_blocks: Summary,
	summary_blocks_translations: SummaryTranslation;
	summary_blocks_data: SummaryData,
	summary_blocks_summary_blocks_data: SummaryRelations,
	summary_blocks_data_translations: SummaryDataTranslation,

	// content

	content_blocks: Content,
	content_translations: ContentTranslation,

	// waypoint

	waypoint_blocks: Waypoint,
	waypoint_blocks_translations: WaypointTranslation,


	// outlined

	outlined_blocks: Outlined,
	outlined_blocks_content: OutlinedContent,
	outlined_blocks_content_translations: OutlinedContentTranslation,
	outlined_blocks_outlined_block_content: OutlinedRelations,


	// question

	question_blocks: Question,
	question_blocks_translations: QuestionTranslation,

	// button

	button_blocks: Button,
	button_blocks_translations: ButtonTranslation,

	// FAQ

	faq: FAQ,
	faq_translations: FAQTranslation,

}

export type TextTranslation = {
	id: ID,
	languages_code: string,
	text: string,
	markdown: string,
	text_key: string,
}

// Pages

export type Page = {
	id: ID,
	status: 'draft' | 'published' | 'archived' ,
	translations: ID,
	blocks: ID[],
	image: string
}

export type PagesBlocks = {
	id: ID,
	pages_id: ID,
	order: number,
	collection: string,
	item: ID,
}

export type PageTranslation = {
	id: ID,
	pages_id: ID,
	languages_code: string,
	title: string,
	description: string,
	short_description: string,
	search_tags: string[],
}

// # categories

export type Category = {
	id: ID,
	translations: ID, //reference to CategoryTranslation
	pages: ID[], // M2M Pages
}

export type CategoryTranslation = {
	id: ID,
	name: string,
	languages_code: string,
	categories_id: ID,
	description: string
}

export type CategoryPage = {
	id: ID,
	categories_id: ID,
	pages_id: ID,
}


// # page blocks

// ## summary

export type Summary = {
	id: ID,
	advanced: boolean,
	theme: number, // in cms dropdown
}

export type SummaryTranslation = {
	id: ID,
	title: string,
	description: string,
	languages_code: string,
	summary_blocks_id: ID,
}

export type SummaryData = {
	id: ID,
	translations: ID,
}

export type SummaryDataTranslation = {
	id: ID,
	title: string,
	description: string, // markdown
	languages_code: string,
	summary_blocks_data_id: ID,

}

export type SummaryRelations = {
	id: ID,
	summary_blocks_id: ID,
	summary_blocks_data_id: ID,
}

// ## content

export type Content = {
	id: ID,
	translations: ID,
	image: ID, // reference to image file
	image_alignment: ('left' | 'right'),
	image_size: ('fullwidth' | 'twothirds' | 'onethirds') ,
	title_size: ('fullwidth' | 'fit'),
	theme: number, // in cms dropdown
}

export type ContentTranslation = {
	id: ID,
	title: string,
	paragraph: string, //markdown
	subtitle: string,
	languages_code: string,
	content_blocks_id: ID,
}

// ## Waypoints

export type Waypoint = {
	id: ID,
	translations: ID,
}

export type WaypointTranslation = {
	id: ID,
	name: string,
	languages_code: string,
	waypoint_blocks_id: ID,
}

// ## OutlineBlock

export type Outlined = {
	id: ID,
	theme: number,
	content: ID[] // M2M relationship
}

export type OutlinedRelations = {
	id: ID,
	outlined_blocks_id: ID,
	outlined_block_content_id: ID,
}

export type OutlinedContent = {
	id: ID
	translations: ID,
}

export type OutlinedContentTranslation = { 
	id: ID,
	languages_codes: string,
	title: string,
	column_1: string, // markdown
	column_2: string, // markdown
}

// ## Question

export type Question = {
	id: ID,
	translations: ID,
}

export type QuestionTranslation = {
	id: ID,
	languages_code: string,
	question: string,
	option_1_label : string,
	option_2_label : string,
	option_1_result : string,
	option_2_result : string,
	link_label: string,
	link_to_page: string
	question_blocks_id: ID,
}

// ## Button

export type Button = {
	id: ID,
	type: number // enum: 1 === 'download' | 2 === 'url'
	URL: string,
	translation: ID,
	alignment: number,
	whitespace_top_bottom: boolean,
	theme: number 
}

export type ButtonTranslation = {
	id: ID,
	languages_code: string,
	label: string,
}

export type FAQ = {
	id: ID,
	translations: ID,
}

export type FAQTranslation = {
	title: string,
	text: string,  // markdown
}

// files

// export type File = {
// 	id: ID,
// 	storage: string,
// 	filename_disk: string,
// 	filename_download: string,
// 	title: string,
// 	type: string,
// 	folder: string,
// 	uploaded_by: null, // needs to be implemented
// 	uploaded_on: string,
// 	modified_by: null, // needs to be implemented
// 	modified_on: string,
// 	filesize: number, // in bytes
// 	width: number,
// 	height: number,
// 	duration: number,
// 	description: string,
// 	location: string,
// 	tags: (string | undefined)[] | undefined,
// }