import { PartialItem } from '@directus/sdk';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Page, PageTranslation } from '../../../../../utilities/directus';
import {  Header2, Paragraph } from '../../../../typography/Typography';
import { useGlobalContext } from '../../../../contexts/global/GlobalContext';
import Theme from '../../../../../style/theme';
import { BasicLink } from '../../../../basic/buttons/BasicLink';


//search component
const SearchResult = (props: {page: PartialItem<PageTranslation>, setActiveDropdown: (label: string) => void}) => {

	const {getFileURL, pages} = useGlobalContext();

	return(				
		<Container to={`/page/${props.page.pages_id}`} onClick={() => props.setActiveDropdown('')}>
			{ pages && props.page.pages_id &&
				<>				
					<Image url={getFileURL && getFileURL(`${pages[props.page.pages_id].data.image}`)}/>
					<Content>
						<Header2>{props.page.title}</Header2>
						<Paragraph>{props.page.short_description}</Paragraph>
					</Content>
				</>
			}

			{/* <Link to={'page/'+props.page.pages_id}>
					got to this page
			</Link> */}
		</Container>
	);
};

const Container = styled(BasicLink)`

	display: flex;

	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm / 3}px;
	};

		//desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg / 3}px; 
	};

`;

const Image = styled.div<{url: string | undefined}>`
	width: 100px;
	height: 100px;

	background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

const Content  = styled.div`
	
`;

export default SearchResult;