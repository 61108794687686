import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../../../style/theme';
import { BasicLink } from '../../basic/buttons/BasicLink';
import Button from '../../basic/buttons/Button';
import { useGlobalContext } from '../../contexts/global/GlobalContext';
import { useTextContext } from '../../contexts/text/TextContext';
import { StyledReactMarkdown } from '../../routing/routes/page/blocks/components/StyledMarkdown';
import { Paragraph } from '../../typography/Typography';
import { Logo } from './navigation/Navigation';

const Footer = () => {

	const {getFileURL} = useGlobalContext();
	const {t} = useTextContext();

	const ArrowURL = getFileURL && getFileURL('e4ccf404-ac26-40ac-b2b3-af84b291b0c2');
	const LogoURL = getFileURL && getFileURL('0303e32c-5f41-443d-b33d-1706cc781700');

	return (
		<Container>
			<Logo to={'/home'} img={LogoURL}></Logo> 
			<Column> 
				<ColumnHeader>{t('footer.paginas')}</ColumnHeader>
				<Paragraph>
					<BasicLink to={'FAQ'}>{t('footer.pages.faq')}</BasicLink><br/>
					<BasicLink to={'Koppelvlakken'}>{t('footer.pages.koppelvlakken')}</BasicLink><br/>
					<BasicLink to={'contact'}>{t('footer.pages.contact')}</BasicLink>
				</Paragraph>
			</Column>
			<Column>
				<ColumnHeader>{t('footer.support.title')}</ColumnHeader>
				<StyledReactMarkdown components={{p : ({children}) => <Paragraph>{children}</Paragraph>}}>{t('footer.support.data', true)}</StyledReactMarkdown>
			</Column>
			<ArrowUp img={ArrowURL} onClick={() => document.getElementById('scrollingContentWindow')?.scrollTo({top: 0, behavior: 'smooth'})}></ArrowUp>
		</Container>
	);
};

// styled components
const Container = styled.div`

	position: relative;

	background: ${Theme.colors.primary};
	color: ${Theme.colors.neutralLight};

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        padding: ${Theme.responsive.whitespace.sm / 2}px;
		gap: ${Theme.responsive.whitespace.sm}px;
		flex-direction: column;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 2}px;
		gap: ${Theme.responsive.whitespace.lg}px;
		flex-direction: row;
		padding-right: calc(${Theme.responsive.whitespace.lg / 2 }px + 25px);
    };

	flex-shrink: 0;
	overflow: hidden ;
	display: flex;

	z-index: 1;
`;

const Column = styled.div<{overwriteColumnSize?: number}>`

	display: flex;
	flex-direction: column;

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		/* flex-direction: row; */
		gap: ${Theme.responsive.whitespace.sm / 3}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		flex-direction: column;
		gap: ${Theme.responsive.whitespace.lg / 3}px;
		max-width: 30%;
		margin-block: 16px;
    };

`;

const ColumnHeader = styled.div`
	font-weight: bold;
`;

const ArrowUp = styled(Button)<{img:string | undefined}>`

	position: absolute;
	right:0;top:0;

	background-image: url(${p => p.img});
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(0,0,0,0);

	width: 25px;
	height: 25px;

	cursor: pointer;

	transform: rotate(-90deg);

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
        margin: ${Theme.responsive.whitespace.sm / 2}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.lg / 2}px;
    };
`;

export default Footer;