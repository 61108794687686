import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../../style/theme';

type ScrollTrackingProps = {
	trackingPoints: number[],
	scrollTo: (i : number) => void,
}

const ScrollTracking = (props: ScrollTrackingProps) => {

	const [activeMarker, setActiveMarker] = useState<number>(0);

	useEffect(() => {

		const swc = document.getElementById('scrollingContentWindow');
		if(swc === null) return;

		swc.addEventListener('scroll', scrollListener, { passive: true });

		return () => {
			swc.removeEventListener('scroll',scrollListener);
		};
	},[]);

	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollListener = (e: any) => {
		const position = e.srcElement.scrollTop;
		setScrollPosition(position);
	};

	useEffect(() => {
		handleScroll();
	}, [scrollPosition]);

	const handleScroll = () => {
		if(!props.trackingPoints) return;
		// Go through trackingPoints on the page and see if they are ABOVE the given position.
		for(let i = (props.trackingPoints.length - 1); i > -1; i--){

			if(props.trackingPoints[i] <= scrollPosition + (window.innerHeight / 2)){ // Might need to add a check for the last trackingPoint if its too small to reach the given position before hitting the end of the page.
				setActiveMarker(i);
				break;
			}
		}
	};

	return (
		<StickyContainer>
			<Container>
				{ props.trackingPoints && props.trackingPoints.map((value, index) => (
					<Point 
						key={`tracking-point-${value}-${index}`} active={index === activeMarker}
						onClick={() => props.scrollTo(index)}
					/>
				))}
			</Container>

		</StickyContainer>		
	);
};

// styled components

const StickyContainer = styled.div`
	position: sticky;
	top:50%;
	height: 0;
	width: 0;
	z-index: 1;

	@media (max-width: ${Theme.responsive.media.lg}){
		display: none
    };
`;

const Container = styled.div`
	width: 12px;
	display: flex;
	flex-direction: column;

	gap: 10px;


	@media (max-width: ${Theme.responsive.media.lg}){
		transform: ${`translateX(calc(-${Theme.responsive.whitespace.sm}px - 50%))`};

    };
	@media (min-width: ${Theme.responsive.media.lg}){
		transform: ${`translateX(calc(-${Theme.responsive.whitespace.lg}px - 50%))`};
    };
	@media (min-width: ${Theme.responsive.media.xl}){
		transform: ${`translateX(calc(-${Theme.responsive.whitespace.xl * 1.5}px - 50%))`};
    };
`;


type PointProps = {active: boolean};
const Point = styled.div<PointProps>`
	height: 12px; 
	width: 100%;
	${(p : PointProps) => p.active? 'background-color: black' : 'background-color: #c8c8c8'};
	border-radius: 100%;

	transition: all .25s;
	cursor: pointer;
`;

export default ScrollTracking;