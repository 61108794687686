import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../../../style/theme';

export const BasicLink = styled(Link)`
	text-decoration: none;
	color: ${Theme.colors.neutralLight};


	&:hover, :focus {
		text-decoration: underline;
	}

	&:focus {
		/* outline: solid 2px black; */
	}
`;