import { ID } from '@directus/sdk';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { BasicLink } from '../../../basic/buttons/BasicLink';
import Button from '../../../basic/buttons/Button';
import Close from '../../../basic/buttons/CloseButton';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { Header2 } from '../../../typography/Typography';
import DropdownContainer from './components/DropdownContainer';
import { dropdownParentProps } from './Navigation';

const Categories = (props: dropdownParentProps) => {

	const {categories, pages} = useGlobalContext();

	const [activeCategory, setActiveCategory] = useState<ID | undefined>();
	const [lock, setLock] = useState<boolean>(false);

	const ToggleCategoriesDropdown = (val: ID | undefined, locked?: boolean) => {
		setActiveCategory(val);
		locked !== undefined && setLock(locked);
	};

	useEffect(() => {
		if(props.activeDropdown !== 'categories'){
			setActiveCategory(undefined);
		}
	},[props.activeDropdown]);

	useEffect(() => {
		if(activeCategory){
			props.setActiveDropdown('categories');
		}
		else{
			props.setActiveDropdown(undefined);
		}
	},[activeCategory]);

	return (
		<Container>
			{ categories && Object.values(categories).map((val, index) => 
				<Category
					onClick={(e) => {e.currentTarget.blur(); ToggleCategoriesDropdown(val.data.id, true);}}
					onMouseEnter={() => ToggleCategoriesDropdown(val.data.id)}
					key={`category-${index}-${val.data.id}`} selected={val.data.id === activeCategory}
				>
					{val.translation && val.translation.name}
				</Category>
			)}
			{ activeCategory !== undefined && categories && categories[activeCategory] &&
				<DropdownContainer onMouseLeave={() => !lock && ToggleCategoriesDropdown(undefined)}>
					<PagesContainer>
						{ pages && categories[activeCategory].pages.map((pageId, index) => 
	
							<Page key={`dropdowncategory-page-${index}-${pageId}`}>
								<PageLink 
									to={`/page/${pageId}`}
									onClick={() => setActiveCategory(undefined)}
								>
									<Header2 style={{fontSize: '1.1rem'}}>{pages[pageId].translation?.title}</Header2>
								</PageLink>
								{ pages[pageId].waypoints.map((waypoint, index) => 
									<Waypoint 
										key={`waypoint-link-${index}-${waypoint.data?.id}`}
										to={`/page/${pageId}/${index}`}
										onClick={() => setActiveCategory(undefined)}
									>{waypoint.translation?.name}
										
									</Waypoint>
								)}
							</Page>
						)}
					</PagesContainer>
					<Close onClick={() => ToggleCategoriesDropdown(undefined, false)}/>
				</DropdownContainer>
			}
		</Container>
	);
};

// styled components

const Container = styled.div`
	flex-grow: 1;

	display: flex;
	justify-content: start;
	align-items: center;

	@media (max-width: ${Theme.responsive.media.lg}){
		margin: 0 ${Theme.responsive.whitespace.sm}px;
		gap: ${Theme.responsive.whitespace.sm}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg}px;
		margin: 0 ${Theme.responsive.whitespace.lg}px;
    };
`;

const Category = styled(Button)`
	display: flex;
	justify-content: center;

	background: none;
	color: white;

	font-weight: 100;
	/* white-space: nowrap; */

	//mobile
	@media (max-width: ${Theme.responsive.media.lg}){
		font-size: 0.8rem;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 1rem;
    };

	text-decoration: ${(p:{selected:boolean}) => p.selected ? 'underline' : ''};
	cursor: auto;

`;

const PagesContainer = styled.div`
	min-height: 100px;
	flex-grow: 1;
	/* width: 100%; */
	display: flex;
	/* padding-bottom: 100px; // empty space beneath */

	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm * 1.5}px;
		padding-left: ${(366 / 3) + Theme.responsive.whitespace.sm}px; // calculation based on logo width, horizontal padding and left margin/padding of categories.
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg * 1.5}px;
		padding-left: ${(366 / 2) + Theme.responsive.whitespace.lg}px; // calculation based on logo width, horizontal padding and left margin/padding of categories.
    };
`;

const Page = styled.div`

	display: flex;
	flex-direction: column;

	@media (max-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.sm / 3}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		gap: ${Theme.responsive.whitespace.lg / 3}px;
    };
	/* white-space: nowrap; */

`;

const PageLink = styled(BasicLink)`
	@media (max-width: ${Theme.responsive.media.lg}){
		margin-bottom: ${Theme.responsive.whitespace.sm / 3}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-bottom: ${Theme.responsive.whitespace.lg /3}px;
    };
`;

const Waypoint = styled(BasicLink)`
	@media (max-width: ${Theme.responsive.media.lg}){
		font-size: 0.7rem;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 0.9rem;
    };
`;

export default Categories;