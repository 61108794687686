import { ReactNode } from 'react';
import { GlobalContextProvider } from './global/GlobalContext';
import { TextContextProvider } from './text/TextContext';

const ContextProvider = (props:{children: ReactNode}) => {
	return (			
		<TextContextProvider>
			<GlobalContextProvider>
				{props.children}
			</GlobalContextProvider>			
		</TextContextProvider>
	);
};

export default ContextProvider;