import styled from 'styled-components';
import { keyframes } from 'styled-components';
import Theme from '../../../../../style/theme';

const startAnimation = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const DropdownContainer = styled.div`
	position: absolute;
	left:0;top:100%;right:0;
	/* max-height: 50vh; */
	background: ${Theme.colors.primary};
	overflow: hidden;
	display: flex;

	
	@media (max-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.sm / 3}px;
		gap: ${Theme.responsive.whitespace.sm / 3}px;
    };

    //desktop
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 3}px;
		gap: ${Theme.responsive.whitespace.lg / 3}px;
		padding-top: 0;
    };


	animation: ${startAnimation} .25s forwards;
`;

export default DropdownContainer;